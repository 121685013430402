const CONTEXT_KEY: string = "DSO_APPLICATION";
export const SET_DSOE_CONTEXT: string = "SET_DSO_APPLICATION";
const contextData: string = localStorage.getItem(CONTEXT_KEY);
let dsoApplication: any = {};
if (contextData && contextData !== 'undefined') {
    dsoApplication = JSON.parse(contextData);
    const path = location.pathname;
    if(path.indexOf(dsoApplication.projectInfoDetails.dsoAppId) < 0 && path !== '/' && path !== '/dsoeportal') {
        location.pathname = 'dsoeportal';
    }
}

export const initialState = {
    dsoApplication
}

const reducer = (state, action) => {
    switch (action.type) {
        case SET_DSOE_CONTEXT:
            localStorage.setItem(CONTEXT_KEY, JSON.stringify(action.dsoApplication));
            return {
                ...state,
                dsoApplication: action.dsoApplication
            }
        default:
            return state;
    }
}

export default reducer;