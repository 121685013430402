import { BaseService } from "./base.service";

class AppConfigService extends BaseService {
    constructor() {
        super();
        this.baseUrl = process.env.APPCONFIG_API_URL;
    }

    public async getAllProgrammingLanguages(): Promise<any> {
        let url = `${this.baseUrl}/api/v1/supportedversions`;
        try {
            let response = await this.get(url);
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    public async getRuntimeConfigs(dsoAppId: string, repoName: string): Promise<any> {
        let url = `${this.baseUrl}/api/v1/appconfig`;
        try {
            let response: any = await this.get(url, {
                DsoAppId: dsoAppId,
                RepoName: repoName
            });
            return response.data?.map((appConfig: any) => appConfig.runtimeVersionID);
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    public async updateRuntimeConfig(dsoAppId: string, repoName: string, runtimeVersionID: Array<number>): Promise<any> {
        let url = `${this.baseUrl}/api/v1/appconfig`;
        try {
            let response: any = this.put(url, {
                dsoAppId,
                repoName,
                runtimeVersionID
            });
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
}

const ApplicationConfigService = new AppConfigService();

export default ApplicationConfigService;