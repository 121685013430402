import { useState } from "react";
import { Form as BaseForm } from "react-bootstrap";

const convertToProperCase = (value: string): string => {
    let str = value.replace(/([0-9])/g, " $1").replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1");
    str = str.replace(/^./, str[0].toUpperCase());
    return str;
}

export const Form = (props: any) => {

    const {customValidations, onSubmit, errors, onError, setLoader, name, eleName, domElement, mountParcel, singleSpa, unmountSelf, ...rest} = props;
    const [validated, setValidated] = useState(false);

    const handleSubmit = async (event) => {
        setLoader(true);
        const {target: form} = event;
        const inputs = Array.from(form.elements).filter((tag: any) => ["textarea", "input"].includes(tag.tagName.toLowerCase()));
        const errorMessages = {};
        await Promise.all(inputs.map(async (input: any) => {
            if(input.name) {
                let message: string;
                const fieldName = input.dataset?.display ?? convertToProperCase(input.name);
                if(input.validity.valueMissing) {
                    message = `${fieldName} is required`;
                } else if(input.validity.typeMismatch) {
                    message = `${fieldName} is invalid ${input.type}`;
                } else if(input.validity.tooShort) {
                    message = `${fieldName} must be at least ${input.attributes?.minlength?.value} characters long`;
                } else if(input.validity.tooLong) {
                    message = `${fieldName} must not be longer than ${input.attributes?.maxlength?.value} characters`;
                } else if(input.validity.patternMismatch) {
                    message = `${fieldName} is invalid`;
                } else if(input.validity.customError) {
                    message = input.validationMessage;
                } else if(typeof (customValidations ?? {})[input.name] === 'function') {
                    message = await customValidations[input.name](input);
                    if(message) input.classList.add("is-invalid");
                }
                errorMessages[input.name] = message;
            }
        }))

        props.onError({
            ...errors,
            ...errorMessages
        })

        const isInValid = Object.keys(errorMessages).filter(name => errorMessages[name]).length;
        if (isInValid || form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
        } else {
            await onSubmit(event);
        }
        setLoader(false)
    }

    return (
        <BaseForm name={eleName} validated={validated} onSubmit={handleSubmit} {...rest}>
            {props.children}
        </BaseForm>
    );
}