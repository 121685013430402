import { useParams } from "react-router-dom";
import Parcel from "single-spa-react/parcel";
import { mountRootParcel } from 'single-spa';
import { components } from '@deloitte-global-cloud-services//dsoe-portal-core';
import { Modal, Form, Row, Col, Button } from "react-bootstrap";
import { MyApplications } from "../application/breadcrumb-links";
import { useEffect, useState } from "react";
import ScanProfileService from "../../services/scanprofile.service";
import { alertMessage, showMessageOnPopup } from "../../components/MessageAlert";
import { Form as BaseForm } from '../../components/Form'

import AppRegistrationService from "../../services/appreg.service";

import Whitesource from "./Whitesource";
import SonarQube from "./SonarQube";
import Fortify from "./Fortify";
import PrismaCode from "./PrismaCode";
import { useDSOContext } from "../../context/DataLayer";
import APIUtil from "../../utils/APIUtil";

const { Breadcrumb, Loader, Select, Button: AsyncButton, confirmAlert, HelpText } = components;

const ScanEnginePages = {
    Whitesource,
    SonarQube,
    Fortify,
    PrismaCode
}

const ScanDetails = {
    fortify: { application: 'Application', app_version: 'SAST Version', isDASTProfile: 'Is DAST profile', app_version_DAST: 'DAST Version' },
    whitesource: { productName: 'Product Name', projectName: 'Project Name' },
    sonarqube: { projectName: 'Project Name' }
}

const ScanProfileModal = (props: any) => {

    const { onCloseModal, repoName, dsoAppId, selectedProfile, allProfiles, onUpdate, appRegData, ...restProps } = props;
    const [scanEngines, setScanEngines] = useState();
    const [environments, setEnvironments] = useState();
    const [scanProfile, setScanProfile] = useState<ScanProfile>({scanProfileDetails: {isDASTProfile: false}, scanEngine: {}});
    const [errors, setErrors] = useState<any>();
    const [loader, setLoader] = useState(false);
    const [customValidation, setCustomValidation] = useState<Function>();
    const [modalClose, setModalClose] = useState<Function>();
    const editProfile = props?.selectedProfile?.environmentId;
    const isCopyProfile = props?.selectedProfile?.isCopyProfile === true;
    const ScanEngine = ScanEnginePages[scanProfile?.scanEngine?.name];

    useEffect(() => {
        ScanProfileService.getAllScanEngines().then(scanEngines => {
            setScanEngines(scanEngines.map(scanEngine => {
                return {
                    label: scanEngine.scanEngineName,
                    value: scanEngine.scanEngineId
                }
            }));
        });
        ScanProfileService.getAllEnvironments().then(environments => {
            setEnvironments(environments.map(env => {
                return {
                    label: env.description,
                    value: env.environmentId
                }
            }));
        })
    }, []);

    useEffect(() => {
        if (props.selectedProfile) {
            const { scanEngine: scanEngineName, scanEngineId, ...rest } = props.selectedProfile;
            setScanProfile({
                scanEngine: {
                    id: scanEngineId,
                    name: scanEngineName
                },
                scanProfileDetails: {
                    ...scanProfile?.scanProfileDetails,
                    ...rest
                }
            })
        }
    }, [props.selectedProfile]);

    const setProfileDetails = (name: string, value: string) => {
        setScanProfile({
            ...scanProfile,
            scanProfileDetails: {
                ...scanProfile?.scanProfileDetails,
                [name]: value
            }
        });
    }

    const validateDuplicate = (): boolean => {
        let duplicate = false;
        document.getElementById("environment")?.classList?.remove("is-invalid");
        if (!editProfile) {
            const profiles = allProfiles?.find(profile => profile.scanEngineId === scanProfile?.scanEngine?.id);
            const profile = profiles?.[`${scanProfile?.scanEngine?.name?.toLocaleLowerCase()}_Scanprofile`];
            const envProfile = profile?.find((profile: any) => profile.environmentId === scanProfile?.scanProfileDetails?.environmentId);
            if (envProfile !== undefined) {
                duplicate = true;
                document.getElementById("environment")?.classList?.add("is-invalid");
            }
        }
        return duplicate;
    }

    const handleModalClose = () => {
        onCloseModal();
        setErrors({});
        setScanProfile({scanProfileDetails: {isDASTProfile: false}, scanEngine: {}});
        (typeof modalClose === 'function') && modalClose();
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        event.persist();
        const profile = {
            dsoAppId,
            repoName: scanProfile?.scanProfileDetails?.repoName ?? repoName,
            repoShortName: scanProfile?.scanProfileDetails?.repoShortName,
            scanEngine: scanProfile?.scanEngine?.name,
            environment: scanProfile?.scanProfileDetails?.environment,
            scanProfiles: [{
                [`${scanProfile?.scanEngine?.name?.toLowerCase()}_Scanprofile`]: {
                    scanEngineId: scanProfile?.scanEngine?.id,
                    scanProfileDetails: [{
                        ...scanProfile.scanProfileDetails
                    }]
                }
            }]
        };
        if(validateDuplicate() || (customValidation && await customValidation(profile))) return;
        let res: any;
        let message = 'Scan Profile was created successfully';
        if(isCopyProfile) {
            await copyProfileSecrets(profile);
        }
        const isExist = allProfiles?.find(profile => profile.scanEngineId === scanProfile.scanEngine.id) !== undefined;
        if (isExist) {
            message = 'Scan Profile was updated successfully.'
            res = await ScanProfileService.updateScanProfile(profile);
        } else {
            res = await ScanProfileService.createScanProfile(profile);
        }
        if (res?.success) {
            alertMessage({ message });
            onUpdate();
            handleModalClose();
        } else {
            showMessageOnPopup({
                message: `Scan Profile ${!isExist ? 'creation' : 'update'} failed. ${APIUtil.getErrorMessageLink(res?.error?.details)}`
            });
        }
    }

    const copyProfileSecrets = async (profile: any) => {
        const secrets = await ScanProfileService.getScanProfileSecrets(profile.scanEngine, profile.dsoAppId, profile.repoShortName, profile.environment);
        Object.keys(secrets).forEach(key => {
            let keyName = key.slice(key.indexOf(`${profile.repoShortName}-`) + profile.repoShortName.length + 1);
            keyName = keyName.charAt(0).toLowerCase() + keyName.slice(1);
            profile.scanProfiles[0][`${scanProfile?.scanEngine?.name.toLowerCase()}_Scanprofile`].scanProfileDetails[0][keyName] = secrets[key];
        });
    }

    const setFieldError = (name: string, error: string) => {
        setErrors({ ...errors, [name]: error });
    }

    return (
        <Modal {...restProps} onHide={handleModalClose} backdrop="static" className="modal-right" aria-labelledby="contained-modal-title-vcenter" size="lg">
            <Modal.Header closeButton>
                <Modal.Title className="page-title">{isCopyProfile ?  'Copy' : editProfile ? 'Update' : 'Add'} Scan Profile</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <BaseForm setLoader={setLoader} errors={errors} onError={setErrors} id='scanProfileForm' onSubmit={handleSubmit}>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="6">
                            <Form.Label>
                                Scan Engine<span className="text-danger">*</span>
                                <Parcel config={HelpText} mountParcel={mountRootParcel} wrapWith="span" text="Name of the scan engine used to scan your repository." />
                            </Form.Label>
                            <Parcel
                                config={Select}
                                required
                                isDisabled={props.selectedProfile}
                                errorMessage="Please select Scan Engine!"
                                options={scanEngines}
                                defaultValue={scanProfile?.scanEngine?.id}
                                onChange={(selected) => setScanProfile({
                                    ...scanProfile,
                                    scanEngine: {
                                        id: selected.value,
                                        name: selected.label
                                    }
                                })}
                                mountParcel={mountRootParcel} />
                        </Form.Group>
                        <Form.Group as={Col} md="6">
                            <Form.Label>
                                Environment<span className="text-danger">*</span>
                                <Parcel config={HelpText} mountParcel={mountRootParcel} wrapWith="span" text="Environment where the scan is being performed. Needs to match the env added to your pipeline task." />
                            </Form.Label>
                            <Parcel
                                config={Select}
                                required
                                id="environment"
                                isDisabled={editProfile}
                                errorMessage={validateDuplicate() ? 'Scan profile already exists for selected scan engine and environment' : 'Please select Environment!'}
                                options={environments}
                                defaultValue={scanProfile?.scanProfileDetails?.environmentId}
                                onChange={(selected) => setScanProfile({
                                    ...scanProfile,
                                    scanProfileDetails: {
                                        ...scanProfile?.scanProfileDetails,
                                        environmentId: selected.value
                                    }
                                })}
                                mountParcel={mountRootParcel}
                            />
                        </Form.Group>
                    </Row>
                    {ScanEngine && <ScanEngine 
                                        repoName={repoName} isCopyProfile={isCopyProfile} setModalClose={setModalClose}
                                        isEditable={editProfile} setProfileDetails={setProfileDetails} 
                                        profileDetails={scanProfile?.scanProfileDetails} errors={errors} 
                                        setFieldError={setFieldError} setCustomValidation={setCustomValidation}/>}
                </BaseForm>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="white" className="border" onClick={() => handleModalClose()}>Cancel</Button>
                <Parcel
                    mountParcel={mountRootParcel}
                    config={AsyncButton}
                    variant="primary"
                    loader={loader}
                    formId="scanProfileForm">
                    Save
                </Parcel>
            </Modal.Footer>
        </Modal>
    );
}

const ManageScanProfiles = (_props: any) => {

    const { dsoAppId } = useParams();
    const { repoName } = useParams();
    const [loading, setLoading] = useState(true);
    const [reload, setReload] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [scanProfiles, setScanProfiles] = useState([]);
    const [selectedProfile, setSelectedProfile] = useState({});
    const [appRegData, setAppRegData] = useState({});
    const [{ dsoApplication }] = useDSOContext();

    useEffect(() => {
        ScanProfileService.getAllScanProfiles(dsoAppId, repoName).then(profiles => {
            setScanProfiles(profiles);
            setLoading(false);
        });
        AppRegistrationService.getProjectDetails(dsoAppId).then(data => {
            setAppRegData(data)
        })
    }, [reload]);

    const displayScanDetails = (scanEngine: string, scanProfile: any) => {
        let scanProfileDetails = "";
        const scanDetails = ScanDetails[scanEngine];
        if (scanDetails) {
            const scanDetailsKeys = Object.keys(scanDetails);
            scanDetailsKeys.forEach(key => {
                if(scanProfile[key] !== undefined) scanProfileDetails += `${scanDetails[key]}: ${scanProfile[key] === true ? 'Yes' : scanProfile[key] === false ? 'No' : (scanProfile[key] ?? '')}, `
            });
            scanProfileDetails = scanProfileDetails.trim().slice(0, -1);
        } else {
            scanProfileDetails = "N/A";
        }
        return scanProfileDetails;
    }

    const deleteScanProfile = (scanEngineId: number, environmentId: number, scanEngine: string, env: string) => {
        confirmAlert({
            variant: 'danger',
            title: 'Delete Scan Profile',
            message: `Are you sure you want to delete '${scanEngine}' scan profile for environment '${env}'?`,
            onYes: async () => {
                let res = await ScanProfileService.deleteScanProfile({
                    dsoAppId,
                    repoName,
                    environmentId,
                    scanEngineId
                });
                let message = 'Scan Profile was deleted successfully.'

                if (res?.success) {
                    alertMessage({ message });
                    setReload(!reload);
                } else {
                    alertMessage({
                        error: true,
                        message: `Scan Profile Deletion failed. ${APIUtil.getErrorMessageLink(res?.error?.details)}`
                    });
                }
            }
        });
    }

    return (
        <>
            <div className="row pt-3 pb-3">
                <Parcel
                    mountParcel={mountRootParcel}
                    config={Breadcrumb}
                    wrapWith="div"
                    wrapClassName='col'
                    links={[
                        ...MyApplications,
                        {
                            name: dsoApplication?.projectInfoDetails?.projectName,
                            url: `/dsoeportal/viewapp/${dsoAppId}`
                        },
                        {
                            name: "Repositories",
                            url: `/dsoeportal/managerepo/${dsoAppId}`
                        },
                        {
                            name: `${repoName} - Scan Profiles`,
                        }
                    ]}
                ></Parcel>
                <div className="col-12 col-sm-3 col-lg-2 text-end">
                    <a className="btn btn-primary float-right" href={undefined} onClick={() => {
                        setSelectedProfile(undefined);
                        setShowModal(true);
                    }}>Add Scan Profile</a>
                </div>
            </div>
            <Row>
                <div className="col">
                    <div className="bg-white pt-3 pb-3">
                        <h5 className="page-title ps-3">Scan Profiles</h5>
                        <div className="row">
                            <div className="col">
                                <div className="table-container">
                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th style={{ width: '10%' }}>Scan Engine</th>
                                                <th style={{ width: '10%' }}>Environment</th>
                                                <th>Scan Engine Details</th>
                                                <th style={{ width: '10%' }}>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {loading ? (
                                                <tr>
                                                    <td colSpan={6}>
                                                        <Parcel config={Loader} mountParcel={mountRootParcel} />
                                                    </td>
                                                </tr>
                                            ) : (scanProfiles?.length ? scanProfiles?.map((scanProfile: any, index: number) => {
                                                const profileDetails = scanProfile[`${scanProfile.scanEngine.toLowerCase()}_Scanprofile`];
                                                const rows = profileDetails?.map((profile, innerIndex) => {
                                                    return (
                                                        <tr key={`${scanProfile?.scanProfileId}-${profile?.environment}`}>
                                                            <td>{scanProfile.scanEngine}</td>
                                                            <td>{profile.environment}</td>
                                                            <td>
                                                                {
                                                                    displayScanDetails(scanProfile.scanEngine.toLowerCase(), profile)
                                                                }
                                                            </td>
                                                            <td>
                                                                <a className="pe-2" href={undefined} title="Copy/Duplicate" onClick={() => {
                                                                    setSelectedProfile({ ...scanProfile, ...profile, environmentId: undefined, isCopyProfile: true });
                                                                    setShowModal(true);
                                                                }}>
                                                                    <img className="action_icon" src="/assets/images/copy.svg" />
                                                                </a>
                                                                <a className="pe-2" href={undefined} title="Edit Scan Profile" onClick={() => {
                                                                    setSelectedProfile({ ...scanProfile, ...profile });
                                                                    setShowModal(true);
                                                                }}>
                                                                    <img className="action_icon" src="/assets/images/edit.svg" />
                                                                </a>
                                                                <a className="pe-2" href={undefined} title="Delete Scan Profile" onClick={() => deleteScanProfile(scanProfile.scanEngineId, profile.environmentId, scanProfile.scanEngine, profile.environment)}>
                                                                    <img className="action_icon" src="/assets/images/recycle-bin.svg" />
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    )
                                                });
                                                return rows;
                                            }) : (
                                                <tr>
                                                    <td colSpan={6}>
                                                        No Scan profiles found
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Row>
            <ScanProfileModal
                show={showModal}
                repoName={repoName}
                dsoAppId={dsoAppId}
                selectedProfile={selectedProfile}
                allProfiles={scanProfiles}
                appRegData={appRegData}
                onCloseModal={() => {
                    setShowModal(false);
                    setSelectedProfile({});
                }}
                onUpdate={() => {
                    setReload(!reload);
                }}
            />
        </>
    )
}

export default ManageScanProfiles;