import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { mountRootParcel } from 'single-spa';
import Parcel from "single-spa-react/parcel";
import { Modal, Form, Row, Col, InputGroup, Button } from "react-bootstrap";
import { components } from '@deloitte-global-cloud-services//dsoe-portal-core';
import { default as BaseSelect } from 'react-select';
import { MyApplications } from "./breadcrumb-links";
import AppRegistrationService from "../../services/appreg.service";
import ApplicationConfigService from "../../services/appconfig.service";
import GenesisService from "../../services/genesis.service";
import { alertMessage, showMessageOnPopup } from "../../components/MessageAlert";
import { Form as BaseForm } from '../../components/Form';
import { useDSOContext } from "../../context/DataLayer";
import { SET_DSOE_CONTEXT } from "../../context/reducer";
import APIUtil from "../../utils/APIUtil";
import { SanitizedLink } from "./ApplicationHome";

const { Breadcrumb, Loader, Select, Button: AsyncButton, HelpText, TextField } = components;

const RepoModal = (props: any) => {
    const { onCloseModal, repositoryName, application, dsoAppId, ...restProps } = props;
    const [supportedLanguages, setSupportedLanguages] = useState([]);
    const [repository, setRepository] = useState<Repository>();
    const [runtimes, setRuntimes] = useState([]);
    const [newRepo, setNewRepo] = useState(true);
    const [errors, setErrors] = useState<any>();
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        const repo = application?.devConfigDetails?.repoDetails?.find((r: Repository) => r.repoName === props.repositoryName);
        const repoName = (repo?.repoName?.split(",") ?? [])[0]?.trim();
        setRepository({
            ...repo,
            repoName
        });
        if(repoName?.indexOf("/") >= 0) {
            const [monoRepoName, ...path] = repoName?.split("/");
            setRepository({
                ...repo,
                repoName: monoRepoName,
                monorepofolder: path.join("/"),
                isMonoRepo: true
            });
            setNewRepo(false);
        }
        ApplicationConfigService.getRuntimeConfigs(dsoAppId, repoName).then(runtimes => {
            setRuntimes(runtimes)
        });
        setErrors({});
    }, [props.repositoryName])

    useEffect(() => {
        ApplicationConfigService.getAllProgrammingLanguages().then(languages => {
            setSupportedLanguages(languages.map((language: any) => {
                return {
                    label: `${language.runtimeName} - ${language.runtimeVersion}`,
                    value: language.runtimeVersionID
                }
            }));
        });
    }, []);

    const handleModalClose = () => {
        setNewRepo(true)
        setRepository({})
        setRuntimes([])
        onCloseModal();
    };

    const handleChange = (name: string, value: any) => {
        setRepository({
            ...repository, [name]: value
        });
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        event.persist();
        const developerDetails = await getDeveloperDetails();
        const app = Object.assign({}, application);
        if (!app.devConfigDetails) app.devConfigDetails = {};
        app.devConfigDetails.organization = process.env.GITHUB_ORGANIZATION;
        app.devConfigDetails.repoDetails = [{ ...repository }]
        app.devConfigDetails.repoDetails[0].developerDetails = developerDetails;
        app.devConfigDetails.repoDetails[0].scanProfiles = null;

        if(repository.isMonoRepo && repository.monorepofolder) {
            app.devConfigDetails.repoDetails[0].repoName = `${repository.repoName}/${repository.monorepofolder}`
        }

        let res: any;
        let message = `Repository was ${!props.repositoryName ? 'created' : 'updated'} successfully.`;

        if (!props.repositoryName) res = await AppRegistrationService.updateProject(app);
        if (runtimes?.length && (props.repositoryName || res.success)) res = await ApplicationConfigService.updateRuntimeConfig(dsoAppId, app.devConfigDetails.repoDetails[0].repoName, runtimes)

        if (res?.success) {
            handleModalClose();
            alertMessage({ message });
        } else {
            showMessageOnPopup({
                message: `Repository ${!props.repositoryName ? 'creation' : 'update'} failed. ${APIUtil.getErrorMessageLink(res?.error?.details)}`
            });
        }
    };

    const getDeveloperDetails = async () => {
        let developerDetails = [];
        let gitUser1 = await getGitHubUser((repository?.developerDetails ?? [])[0]?.devGithubUserId);
        if (gitUser1) developerDetails[0] = gitUser1;
        let gitUser2 = await getGitHubUser((repository?.developerDetails ?? [])[1]?.devGithubUserId);
        if (gitUser2) developerDetails[1] = gitUser2;
        developerDetails = developerDetails.filter(dev => dev !== undefined);
        return developerDetails;
    }

    const getGitHubUser = async (value): Promise<any> => {
        let gitUser;
        if (value) gitUser = await GenesisService.validaGithHubUser(value, repository.organization);
        return gitUser;
    }

    const validateGitHubUser = async (inputRef): Promise<string> => {
        let message: string;
        if (!props.repositoryName && inputRef?.value) {
            const user = await getGitHubUser(inputRef.value);
            message = (!inputRef.value || user) ? undefined : 'GitHub user id/ email not found';
        }
        return message;
    }

    const customValidations = {
        repoName: async (inputRef): Promise<string> => {
            let message: string;
            if (!props.repositoryName && inputRef?.value) {
                if (repository.isADORepo && repository.adoOrganization && repository.adoProjectName && repository.adoOrgToken) {
                    const isValid = await AppRegistrationService.isValidADORepo({
                        repoName: repository.repoName,
                        organization: repository.adoOrganization,
                        projectName: repository.adoProjectName,
                        orgToken: repository.adoOrgToken
                    });
                    if (!isValid) message = `Repository Name ${inputRef?.value} doesn't exists in ADO`
                } else {
                    const isValid = await GenesisService.isValidGithHubRepo(inputRef?.value, repository.organization);
                    if (newRepo && isValid) {
                        message = `Repository Name ${inputRef?.value} already exists in GitHub`
                    } else if (!newRepo && !isValid) {
                        message = `Repository Name ${inputRef?.value} doesn't exists in GitHub`
                    }
                }
            }
            return message;
        },
        developer1: validateGitHubUser,
        developer2: validateGitHubUser
    }

    const setFieldError = (name, error) => {
        setErrors({ ...errors, [name]: error });
    }

    const getSelectedGitHubOrg = (organization: string) => {
        const organizations = AppRegistrationService.getGitOrganizations();
        return organizations.find(org => org.value === organization);
    }

    return (
        <Modal {...restProps} onHide={handleModalClose} backdrop="static" className="modal-right" aria-labelledby="contained-modal-title-vcenter" size="lg">
            <Modal.Header closeButton>
                <Modal.Title className="page-title">{props.repositoryName ? 'Update' : 'Add'} Repository</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <BaseForm setLoader={setLoader} customValidations={customValidations} errors={errors} onError={setErrors} id='repoForm' onSubmit={handleSubmit}>
                    <Row className="mb-3">
                        {!props.repositoryName && (
                            <Form.Group as={Col} md="6">
                                <Form.Label>
                                    Is this a new Repository? <span className="text-danger">*</span>
                                    <Parcel config={HelpText} mountParcel={mountRootParcel} wrapWith="span" text="Are you creating a new GitHub code repository? Or are you using an existing GitHub/ADO code repository? " />
                                </Form.Label>
                                <InputGroup>
                                    <Form.Check
                                        required
                                        id="newRepo"
                                        name="isRepoExists"
                                        inline
                                        type="radio"
                                        label="Yes"
                                        checked={newRepo}
                                        onChange={(e) => {
                                            document.getElementById("repoName").classList.remove("is-invalid");
                                            setNewRepo(true);
                                            handleChange("isADORepo", false);
                                        }}
                                    />
                                    <Form.Check
                                        required
                                        id="existingRepo"
                                        name="isRepoExists"
                                        inline
                                        type="radio"
                                        label="No"
                                        checked={!newRepo}
                                        onChange={(e) => {
                                            document.getElementById("repoName").classList.remove("is-invalid");
                                            setNewRepo(false)
                                        }}
                                    />
                                </InputGroup>
                            </Form.Group>
                        )}
                        <Form.Group as={Col} md={props.repositoryName ? 12 : 6}>
                            <Form.Label>
                                Repository Name<span className="text-danger">*</span>
                                <Parcel config={HelpText} mountParcel={mountRootParcel} wrapWith="span" text="The name of the code repository, new or existing, to be associated with the registered DSO application." />
                            </Form.Label>
                            <Parcel
                                config={TextField}
                                mountParcel={mountRootParcel}
                                id="repoName"
                                required
                                minLength={3}
                                maxLength={100}
                                type="text"
                                eleName="repoName"
                                data-display="Repository Name"
                                defaultValue={repository?.repoName ?? ''}
                                readOnly={props.repositoryName}
                                disabled={props.repositoryName}
                                onChange={(e: any) => {
                                    handleChange(e.target.name, e.target.value);
                                }}
                                onError={setFieldError}
                                error={errors?.repoName}
                            />
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="6">
                            <Form.Label>
                                Repository Source<span className="text-danger">*</span>
                                <Parcel config={HelpText} mountParcel={mountRootParcel} wrapWith="span" text="Where the repository resides. ADO or GitHub." />
                            </Form.Label>
                            <InputGroup>
                                <Form.Check
                                    required
                                    id="github"
                                    name="isADORepo"
                                    inline
                                    type="radio"
                                    label="GitHub"
                                    checked={!repository?.isADORepo}
                                    disabled={props.repositoryName || newRepo}
                                    onChange={(e) => {
                                        handleChange(e.target.name, false);
                                    }}
                                />
                                <Form.Check
                                    required
                                    id="ado"
                                    name="isADORepo"
                                    inline
                                    type="radio"
                                    label="Azure DevOps"
                                    disabled={props.repositoryName || newRepo}
                                    checked={repository?.isADORepo}
                                    onChange={(e) => {
                                        handleChange(e.target.name, true);
                                    }}
                                />
                            </InputGroup>
                        </Form.Group>
                        {repository?.isADORepo ? (
                            <Form.Group as={Col} md="6">
                                <Form.Label>
                                    ADO Organization Name<span className="text-danger">*</span>
                                    <Parcel config={HelpText} mountParcel={mountRootParcel} wrapWith="span" text="Name of the ADO organization where your repository exists." />
                                </Form.Label>
                                <Parcel
                                    config={TextField}
                                    mountParcel={mountRootParcel}
                                    id="adoOrganization"
                                    required
                                    maxLength={50}
                                    type="text"
                                    eleName="adoOrganization"
                                    data-display='ADO Organization Name'
                                    defaultValue={repository?.adoOrganization ?? ''}
                                    readOnly={props.repositoryName}
                                    disabled={props.repositoryName}
                                    onChange={(e: any) => {
                                        handleChange(e.target.name, e.target.value);
                                    }}
                                    onError={setFieldError}
                                    error={errors?.adoOrganization}
                                />
                                <Form.Control.Feedback type="invalid">{errors?.adoOrganization}</Form.Control.Feedback>
                            </Form.Group>
                        ) : (props.repositoryName !== undefined && props.repositoryName?.trim().length > 0) ? (
                            <Form.Group as={Col} md="6">
                                <Form.Label>
                                    GitHub Organization Name<span className="text-danger">*</span>
                                    <Parcel config={HelpText} mountParcel={mountRootParcel} wrapWith="span" text="Name of the GitHub organization where your repository exists." />
                                </Form.Label>
                                <Parcel
                                    config={TextField}
                                    mountParcel={mountRootParcel}
                                    id="organization1"
                                    required
                                    maxLength={50}
                                    type="text"
                                    eleName="organization"
                                    data-display='Organization Name'
                                    defaultValue={repository?.organization ?? ''}
                                    readOnly
                                    disabled
                                    onChange={(e: any) => {
                                        handleChange(e.target.name, e.target.value);
                                    }}
                                    onError={setFieldError}
                                    error={errors?.organization}
                                />
                                <Form.Control.Feedback type="invalid">{errors?.organization}</Form.Control.Feedback>
                            </Form.Group>
                        ) : (
                            <Form.Group as={Col} md="6">
                                <Form.Label>
                                    GitHub Organization Name<span className="text-danger">*</span>
                                    <Parcel config={HelpText} mountParcel={mountRootParcel} wrapWith="span" text="Name of the GitHub organization where your repository exists." />
                                </Form.Label>
                                <BaseSelect
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    onChange={(selected) => {
                                        handleChange('organization', selected.value);
                                    }}
                                    defaultValue={getSelectedGitHubOrg(repository?.organization)}
                                    options={AppRegistrationService.getGitOrganizations()}
                                />
                                <input
                                    tabIndex={-1}
                                    autoComplete="off"
                                    style={{
                                        opacity: 0,
                                        width: "100%",
                                        height: 0,
                                        position: "absolute"
                                    }}
                                    onChange={() => {}}
                                    required
                                    name="organization"
                                    value={repository?.organization ?? ''}
                                />
                                <Form.Control.Feedback type="invalid">{errors?.organization}</Form.Control.Feedback>
                            </Form.Group>
                        )}
                    </Row>
                    {(!repository?.isADORepo && !newRepo) && (
                        <Row className="mb-3">
                            <Form.Group as={Col} md="6">
                                <Form.Label>
                                    Is this a monorepo? <span className="text-danger">*</span>
                                    <Parcel config={HelpText} mountParcel={mountRootParcel} wrapWith="span" text="A monorepo is a single repository that holds the source code for multiple applications and libraries." />
                                </Form.Label>
                                <InputGroup>
                                    <Form.Check
                                        required
                                        id="monorepo"
                                        name="isMonoRepo"
                                        inline
                                        type="radio"
                                        label="Yes"
                                        checked={repository?.isMonoRepo}
                                        disabled={props.repositoryName}
                                        onChange={(e) => {
                                            handleChange(e.target.name, true);
                                        }}
                                    />
                                    <Form.Check
                                        required
                                        id="nomonorepo"
                                        name="isMonoRepo"
                                        inline
                                        type="radio"
                                        label="No"
                                        disabled={props.repositoryName}
                                        checked={!repository?.isMonoRepo}
                                        onChange={(e) => {
                                            handleChange(e.target.name, false);
                                        }}
                                    />
                                </InputGroup>
                            </Form.Group>
                            <Form.Group as={Col} md="6">
                                <Form.Label>
                                    Monorepo Folder{(repository?.isMonoRepo || props.repositoryName) && (<span className="text-danger">*</span>)}
                                </Form.Label>
                                <Parcel
                                    config={TextField}
                                    mountParcel={mountRootParcel}
                                    id="monorepofolder"
                                    required={repository?.isMonoRepo}
                                    disabled={!repository?.isMonoRepo || props.repositoryName}
                                    readonly={!repository?.isMonoRepo}
                                    maxLength={50}
                                    type="text"
                                    eleName="monorepofolder"
                                    data-display='Monorepo Folder'
                                    defaultValue={repository?.monorepofolder ?? ''}
                                    onChange={(e: any) => {
                                        handleChange(e.target.name, e.target.value);
                                    }}
                                    onError={setFieldError}
                                    error={errors?.monorepofolder}
                                />
                                <Form.Control.Feedback type="invalid">{errors?.developer2}</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                    )}
                    {(newRepo && !repository?.isADORepo) && (
                            <Row className="mb-3">
                                <Form.Group as={Col} md="6">
                                    <Form.Label>
                                        Developer 1<span className="text-danger">*</span>
                                        <Parcel config={HelpText} mountParcel={mountRootParcel} wrapWith="span" text="Name of developer with admin rights to the GitHub repository. " />
                                    </Form.Label>
                                    <Parcel
                                        required={!props.repositoryName}
                                        config={TextField}
                                        mountParcel={mountRootParcel}
                                        type="text"
                                        id="developer1"
                                        eleName="developer1"
                                        data-display='Developer 1'
                                        readOnly={props.repositoryName}
                                        disabled={props.repositoryName}
                                        defaultValue={(repository?.developerDetails ?? [])[0]?.devGithubUserId ?? ''}
                                        onChange={(eve: any) => {
                                            eve.target.classList.remove("is-invalid");
                                            setRepository({
                                                ...repository,
                                                developerDetails: [
                                                    {
                                                        devGithubUserId: eve.target.value
                                                    },
                                                    (repository?.developerDetails ?? [])[1]
                                                ]
                                            });
                                        }}
                                        onError={setFieldError}
                                        error={errors?.developer1}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors?.developer1}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="6">
                                    <Form.Label>
                                        Developer 2
                                        <Parcel config={HelpText} mountParcel={mountRootParcel} wrapWith="span" text="Name of the second developer with admin rights to the GitHub repository. " />
                                    </Form.Label>
                                    <Parcel
                                        config={TextField}
                                        mountParcel={mountRootParcel}
                                        type="text"
                                        id="developer2"
                                        eleName="developer2"
                                        data-display='Developer 2'
                                        readOnly={props.repositoryName}
                                        disabled={props.repositoryName}
                                        defaultValue={(repository?.developerDetails ?? [])[1]?.devGithubUserId ?? ''}
                                        onChange={(eve: any) => {
                                            eve.target.classList.remove("is-invalid");
                                            setRepository({
                                                ...repository,
                                                developerDetails: [
                                                    (repository?.developerDetails ?? [])[0],
                                                    {
                                                        devGithubUserId: eve.target.value
                                                    }
                                                ]
                                            });
                                        }}
                                        onError={setFieldError}
                                        error={errors?.developer2}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors?.developer2}</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                        )}
                    {repository?.isADORepo && (
                        <Row className="mb-3">
                            <Form.Group as={Col} md="6">
                                <Form.Label>
                                    ADO Project Name<span className="text-danger">*</span>
                                    <Parcel config={HelpText} mountParcel={mountRootParcel} wrapWith="span" text="Name of the ADO project where the repository resides." />
                                </Form.Label>
                                <Parcel
                                    config={TextField}
                                    mountParcel={mountRootParcel}
                                    id="adoProjectName"
                                    required
                                    maxLength={100}
                                    type="text"
                                    eleName="adoProjectName"
                                    data-display='ADO Project Name'
                                    defaultValue={repository?.adoProjectName ?? ''}
                                    readOnly={props.repositoryName}
                                    disabled={props.repositoryName}
                                    onChange={(e: any) => {
                                        handleChange(e.target.name, e.target.value);
                                    }}
                                    onError={setFieldError}
                                    error={errors?.adoProjectName}
                                />
                                <Form.Control.Feedback type="invalid">{errors?.adoProjectName}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="6">
                                <Form.Label>
                                    ADO Access Token<span className="text-danger">*</span>
                                    <Parcel config={HelpText} mountParcel={mountRootParcel} wrapWith="span" text="Organization token with read access to the repository in ADO." />
                                </Form.Label>
                                <Parcel
                                    config={TextField}
                                    mountParcel={mountRootParcel}
                                    id="adoOrgToken"
                                    required
                                    maxLength={100}
                                    type="password"
                                    eleName="adoOrgToken"
                                    data-display='ADO Access Token'
                                    defaultValue={repository?.adoOrgToken ?? ''}
                                    readOnly={props.repositoryName}
                                    disabled={props.repositoryName}
                                    onChange={(e: any) => {
                                        handleChange(e.target.name, e.target.value);
                                    }}
                                    onError={setFieldError}
                                    error={errors?.adoOrgToken}
                                />
                                <Form.Control.Feedback type="invalid">{errors?.adoOrgToken}</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                    )}
                    <Row className="mb-3">
                        <Form.Group as={Col} md="12">
                            <Form.Label>
                                Programming Language<span className="text-danger">*</span>
                                <Parcel config={HelpText} mountParcel={mountRootParcel} wrapWith="span" text="The supported programming language used by the scan engine in the selected code repository." />
                            </Form.Label>
                            <Parcel config={Select} required
                                errorMessage="This field is required.!"
                                defaultValue={runtimes}
                                mountParcel={mountRootParcel}
                                options={supportedLanguages}
                                onChange={(selected) => setRuntimes(selected.map(opt => opt.value))}
                                isMulti />
                        </Form.Group>
                    </Row>
                </BaseForm>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="white" className="border" onClick={() => handleModalClose()}>Cancel</Button>
                <Parcel
                    mountParcel={mountRootParcel}
                    config={AsyncButton}
                    variant="primary"
                    loader={loader}
                    formId="repoForm">
                    Save
                </Parcel>
            </Modal.Footer>
        </Modal>
    )
}

const ManageRepositories = () => {

    const { dsoAppId } = useParams();
    const [application, setApplication] = useState(undefined);
    const [selectedRepository, setSelectedRepository] = useState<string>("");
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [{ dsoApplication }, dispatch] = useDSOContext();

    useEffect(() => {
        setApplication(dsoApplication);
        setLoading(false);
    }, []);

    return (
        <>
            <div className="row pt-3 pb-3">
                <Parcel
                    mountParcel={mountRootParcel}
                    config={Breadcrumb}
                    wrapWith="div"
                    wrapClassName='col'
                    links={[
                        ...MyApplications,
                        {
                            name: application?.projectInfoDetails?.projectName,
                            url: `/dsoeportal/viewapp/${dsoAppId}`
                        },
                        {
                            name: 'Repositories'
                        }
                    ]}
                ></Parcel>
                <div className="col-12 col-sm-6 col-lg-4 text-end">
                    <a className="btn btn-primary float-right" href={undefined} onClick={() => {
                        setSelectedRepository("");
                        setShowModal(true)
                    }}>Add Repository</a>
                </div>
            </div>
            <Row>
                <div className="col">
                    <div className="bg-white pt-3 pb-3">
                        <h5 className="page-title ps-3">Repositories</h5>
                        <div className="row">
                            <div className="col">
                                <div className="table-container">
                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th style={{ width: '20%' }}>Repository Name</th>
                                                <th style={{ width: '20%' }}>Organization</th>
                                                <th style={{ width: '10%' }}>Repository Source</th>
                                                <th style={{ width: '40%' }}>URL</th>
                                                <th style={{ width: '15%' }}>Developer Email</th>
                                                <th style={{ width: '10%' }}>Scan Engines</th>
                                                <th style={{ width: '5%' }}>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {loading ? (
                                                <tr>
                                                    <td colSpan={6}>
                                                        <Parcel config={Loader} mountParcel={mountRootParcel} />
                                                    </td>
                                                </tr>
                                            ) : (application?.devConfigDetails?.repoDetails?.length ? application?.devConfigDetails?.repoDetails?.map((repo: any, index: number) => {
                                                const repoDetails = repo.repoName.split(",");
                                                const repoName = repoDetails[0].trim();
                                                return (<tr key={repoName}>
                                                    <td>{repoName}</td>
                                                    <td>{repo.isADORepo ? repo.adoOrganization : repo.organization}</td>
                                                    <td>{repo.isADORepo ? 'Azure DevOps' : 'GitHub'}</td>
                                                    <td><a href={repoDetails[1]?.substring(6)} target="_blank">{repoDetails[1]?.substring(6)}</a></td>
                                                    <td>{(repo.developerDetails ?? [])[0]?.developerEmailId}</td>
                                                    <td>
                                                    <SanitizedLink prefix={"/scanprofiles"} id={application?.projectInfoDetails?.dsoAppId} suffix={`/${encodeURIComponent(repoName)}`} className="text-decoration-none" state={{ repositories: application?.devConfigDetails?.repoDetails }}>
                                                            Manage Profile
                                                        </SanitizedLink>
                                                    </td>
                                                    <td>
                                                        <a href={undefined} title="Edit Repository" onClick={() => {
                                                            setSelectedRepository(repo.repoName)
                                                            setShowModal(true)
                                                        }}>
                                                            <img className="action_icon" src="/assets/images/edit.svg" />
                                                        </a>
                                                    </td>
                                                </tr>)
                                            }) : (
                                                <tr>
                                                    <td colSpan={6}>
                                                        No Repositories found
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Row>
            <RepoModal
                repositoryName={selectedRepository}
                application={application}
                show={showModal}
                dsoAppId={dsoAppId}
                onCloseModal={() => {
                    setSelectedRepository("")
                    setShowModal(false);
                    setLoading(true);
                    AppRegistrationService.getProjectDetails(dsoAppId).then(application => {
                        setApplication(application);
                        setLoading(false);
                        dispatch({
                            type: SET_DSOE_CONTEXT,
                            dsoApplication: application
                        });
                    });
                }}
            />
        </>
    );
}

export default ManageRepositories;