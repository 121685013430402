import Parcel from "single-spa-react/parcel";
import { mountRootParcel } from 'single-spa';
import { components } from '@deloitte-global-cloud-services//dsoe-portal-core';
import UserService from '../services/user.service'

const {Select} = components;

export const UserSelect = (props) => {

    return (
        <Parcel
            errorMessage="Please select user!"
            mountParcel={mountRootParcel}
            config={Select}
            isAsync={true}
            defaultInputValue={props.defaultValue}
            loadOptions={async (inputValue) => {
                const res: any = await UserService.listUsers(inputValue);
                const data = res?.map((user: any) => {
                    return {
                        label: `${user.displayName} <${user.mail}>`,
                        value: user.mail,
                        displayName: user.displayName
                    }
                }).filter((user: any) => user.value);
                return data;
            }}
            {...props}
        ></Parcel>
    )
}