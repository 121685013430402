import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Parcel from "single-spa-react/parcel";
import { mountRootParcel } from 'single-spa';
import { components, util } from '@deloitte-global-cloud-services//dsoe-portal-core';
import BackendAPIService from "../../services/backendapi.service";
import { MyApplications } from "./breadcrumb-links";
import { useDSOContext } from "../../context/DataLayer";
import moment from "moment";
import { CustomTable } from "../../components/CustomTable";

const { Breadcrumb } = components;
const { DateUtil } = util;

const BuildHistory = () => {

    const { dsoAppId } = useParams();
    const [data, setData] = useState(undefined);
    const [allData, setAllData] = useState(undefined);
    const [filteredData, setFilteredData] = useState(undefined);
    const [searchText, setSearchText] = useState('');
    const [{ dsoApplication }] = useDSOContext();

    useEffect(() => {
        BackendAPIService.getAllBuildHistory(dsoAppId).then(data => setData(data));
    }, []);

    useEffect(() => {
        BackendAPIService.getAllBuildHistory(dsoAppId, data?.startIndex, data?.totalRecords).then(allData => setAllData(allData));
    }, [data]);

    useEffect(() => {
        setFilteredData(allData?.buildsModels);
    }, [allData]);

    const sortCreatedDate = (rowA: any, rowB: any) => {
        const dateA = moment.utc(rowA.createdDate).toDate();
        const dateB = moment.utc(rowB.createdDate).toDate();

        if (dateA > dateB) {
            return 1;
        }

        if (dateB > dateA) {
            return -1;
        }

        return 0;
    };

    const columns = [
        {
            name: 'Repo Name',
            selector: (row: { repoName: any; }) => row.repoName,
            sortable: true,
            wrap: true
        },
        {
            name: 'Build Version',
            selector: (row: { buildNumber: any; }) => row.buildNumber,
            sortable: true,
            wrap: true
        },
        {
            name: 'Date',
            selector: (row: { createdDate: moment.MomentInput; }) => DateUtil.toDisplayDateTime(moment.utc(row.createdDate).toDate()),
            sortable: true,
            sortFunction: sortCreatedDate,
            wrap: true
        }
    ]

    //Global Search
    const handleSearchChange = (event: { target: { value: any; }; }) => {
        setSearchText(event.target.value);

        const filtered = allData?.buildsModels?.filter((column: { [x: string]: any; }) => {

            for (let key in column) {

                if (key === 'repoName' || key === 'buildNumber' || key === 'createdDate') {
                    let value = column[key];

                    if (key === 'createdDate') {
                        console.log("before value", value);
                        value = DateUtil.toDisplayDateTime(moment.utc(value).toDate());
                        console.log("after value", value);
                    }

                    if (value.toLowerCase().includes(event.target.value.toLowerCase())) {
                        return true;
                    }
                }
            }
            return false;
        });

        setFilteredData(filtered);
    }

    return (
        <>
            <div className="row pt-3 pb-3">
                <Parcel
                    mountParcel={mountRootParcel}
                    config={Breadcrumb}
                    wrapWith="div"
                    wrapClassName='col'
                    links={[
                        ...MyApplications,
                        {
                            name: dsoApplication?.projectInfoDetails?.projectName,
                            url: `/dsoeportal/viewapp/${dsoAppId}`
                        },
                        {
                            name: 'Build History'
                        }
                    ]}
                ></Parcel>
            </div>
            <div className="row">
                <div className="col">
                    <div className="bg-white pt-3 pb-3">
                        <h5 className="page-title ps-3">Build History</h5>
                        <div className="d-flex offset-md-9">
                            <input
                                className="form-control"
                                type="text"
                                placeholder="Search"
                                value={searchText}
                                onChange={handleSearchChange}
                            />
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="table-container">
                                    <CustomTable
                                        columns={columns}
                                        data={filteredData}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default BuildHistory;