import { BaseService } from "./base.service";

class ScanProfileAPIService extends BaseService {
    constructor() {
        super();
        this.baseUrl = process.env.SCANPROFILE_API_URL;
    }

    public async getAllScanEngines(): Promise<any> {
        let url = `${this.baseUrl}/api/v1/scanengines`;
        try {
            const res = await this.get(url);
            return res.data;
        } catch(e) {
            console.error(e);
        }
    }

    public async getAllEnvironments(): Promise<any> {
        let url = `${this.baseUrl}/api/v1/environments`;
        try {
            const res = await this.get(url);
            return res.data;
        } catch(e) {
            console.error(e);
        }
    }

    public async getAllScanProfiles(dsoAppId: string, repo: string): Promise<any> {
        let url = `${this.baseUrl}/api/v1/scanprofile`;
        try {
            const res = await this.get(url, {
                dsoAppId,
                repo
            });
            return res.data;
        } catch(e) {
            console.error(e);
        }
    }

    public async getScanProfileSecrets(scanEngine: string, dsoAppId: string, repoShortName: string, environment: string): Promise<any> {
        let url = `${this.baseUrl}/api/v1/scanprofile/GetSecrets`;
        try {
            const res = await this.get(url, {
                dsoAppId,
                repoShortName,
                scanEngine,
                environment
            });
            return res.data;
        } catch(e) {
            console.error(e);
        }
    }

    public async createScanProfile(profile: any): Promise<any> {
        let url = `${this.baseUrl}/api/v1/scanprofile`;
        try {
            const res = await this.post(url, profile);
            return res;
        } catch(e) {
            console.error(e);
        }
    }

    public async updateScanProfile(profile: any): Promise<any> {
        let url = `${this.baseUrl}/api/v1/scanprofile`;
        try {
            const res = await this.put(url, profile);
            return res;
        } catch(e) {
            console.error(e);
        }
    }

    public async deleteScanProfile(profile: any): Promise<any> {
        let url = `${this.baseUrl}/api/v1/scanprofile/remove`;
        try {
            const res = await this.post(url, profile);
            return res;
        } catch(e) {
            console.error(e);
        }
    }
}

const ScanProfileService = new ScanProfileAPIService();

export default ScanProfileService;