import { Modal } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import InputGroup from 'react-bootstrap/InputGroup';
import { components, util } from '@deloitte-global-cloud-services//dsoe-portal-core';
import Parcel from "single-spa-react/parcel";
import { mountRootParcel } from 'single-spa';
import BackendAPIService from "../../services/backendapi.service";
import { MyApplications } from "./breadcrumb-links";
import { alertMessage, showMessageOnPopup } from "../../components/MessageAlert";
import { useDSOContext } from "../../context/DataLayer";
import { Form as BaseForm } from '../../components/Form'
import APIUtil from '../../utils/APIUtil';

const { Breadcrumb, Select, DatePicker, Button: AsyncButton, HelpText, TextField } = components;
const { DateUtil } = util;

const AddAssetModal = (props: any) => {

    const { assetData, onHide, onUpdateAsset, ...restProps } = props;
    const [validated, setValidated] = useState(false);
    const [asset, setAsset] = useState(assetData);
    const [assetTypes, setAssetTypes] = useState(undefined);
    const [errors, setErrors] = useState<any>();
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        setAsset(props.assetData)
    }, [props.assetData]);

    useEffect(() => {
        BackendAPIService.getAssetTypes().then(data => setAssetTypes(data));
    }, []);

    const handleChange = (event: any) => {
        const input = event.target ? event.target : event.currentTarget;
        setAsset({ ...asset, [input.name]: input.value })
    }

    const handleSelectChange = (property: string, value: string) => {
        setAsset({ ...asset, [property]: value })
    }

    const handleModalClose = () => {
        onHide();
        setAsset({})
        setValidated(false);
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        event.persist()
        let response: any;
        let message = 'Asset was created successfully.';

        if (asset.applicationId === undefined) response = await BackendAPIService.createAsset(asset);
        else {
            message = 'Asset was updated successfully.';
            response = await BackendAPIService.updateAsset(asset);
        }
        if (response?.success) {
            alertMessage({ message });
            handleModalClose()
            onUpdateAsset();
        } else {
            showMessageOnPopup({
                message: `Asset ${asset.applicationId === undefined ? 'creation' : 'update'} failed. ${APIUtil.getErrorMessageLink(response?.error?.details)}`
            });
        }
    };

    const setFieldError = (name, error) => {
        setErrors({ ...errors, [name]: error });
    }

    return (
        <Modal {...restProps} onHide={handleModalClose} backdrop='static' aria-labelledby="contained-modal-title-vcenter" className='modal-right'>
            <Modal.Header closeButton >
                <Modal.Title id="contained-modal-title-vcenter">
                    {asset.applicationId === undefined ? 'Add' : 'Update'} an Asset
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <BaseForm setLoader={setLoader} errors={errors} onError={setErrors} id="assetForm" onSubmit={handleSubmit}>
                    <Row className="mb-3">
                        <Form.Group as={Col} controlId="formGridState">
                            <Form.Label><b>Asset type</b>
                                <Parcel config={HelpText} mountParcel={mountRootParcel} wrapWith="span" text="Category of your asset." />
                            </Form.Label>
                            <Parcel
                                eleName="assetType"
                                required
                                defaultValue={asset.assetType ? asset.assetType : ''}
                                onChange={(selected) => { handleSelectChange('assetType', selected.value) }}
                                errorMessage="Please select Asset type.!"
                                mountParcel={mountRootParcel}
                                config={Select}
                                options={assetTypes?.map((value: any, index: number) => { return { value: value.assetType, label: value.assetType } }
                                )}
                            ></Parcel>
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridName">
                            <Form.Label><b>Name</b>
                                <Parcel config={HelpText} mountParcel={mountRootParcel} wrapWith="span" text="Unique name to identify your asset." />
                            </Form.Label>
                            <Parcel
                                config={TextField}
                                mountParcel={mountRootParcel}
                                id="assetName"
                                required
                                minLength={3}
                                maxLength={100}
                                type="text"
                                eleName="assetName"
                                data-display="Asset Name"
                                defaultValue={asset?.assetName ?? ''}
                                onChange={(e: any) => {
                                    handleChange(e);
                                }}
                                onError={setFieldError}
                                error={errors?.assetName}
                            />
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} controlId="formGridExpiryDate">
                            <Form.Label><b>Expiry Date</b>
                                <Parcel config={HelpText} mountParcel={mountRootParcel} wrapWith="span" text="Date when your asset is no longer valid. I.E. password expired." />
                            </Form.Label>
                            <Parcel
                                config={DatePicker}
                                required
                                mountParcel={mountRootParcel}
                                selected={asset.expirationDate ? new Date(asset.expirationDate) : ""}
                                eleName="expirationDate"
                                onChange={(value: Date) => {
                                    setAsset({ ...asset, expirationDate: value })
                                }}
                            />
                            <Form.Control.Feedback type="invalid">Enter Asset Name.!</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridEnvironment">
                            <Form.Label><b>Environment</b>
                                <Parcel config={HelpText} mountParcel={mountRootParcel} wrapWith="span" text="Environment where your asset is used (SBX,DEV,NPD,PRD)" />
                            </Form.Label>
                            <Parcel
                                config={TextField}
                                mountParcel={mountRootParcel}
                                id="environmentType"
                                required
                                minLength={3}
                                maxLength={100}
                                type="text"
                                eleName="environmentType"
                                data-display="Environment-Type"
                                defaultValue={asset?.environmentType ?? ''}
                                onChange={(e: any) => { handleChange(e); }}
                                onError={setFieldError}
                                error={errors?.environmentType}
                            />
                        </Form.Group>
                    </Row>
                    <Form.Group className="mb-3" controlId="formGridDescription">
                        <Form.Label><b>Description</b>
                            <Parcel config={HelpText} mountParcel={mountRootParcel} wrapWith="span" text="Description of the asset and the purpose of its usage." />
                        </Form.Label>
                        <Parcel
                            config={TextField}
                            mountParcel={mountRootParcel}
                            id="assetDescription"
                            required
                            maxLength={100}
                            rows={3}
                            as="textarea"
                            eleName="assetDescription"
                            data-display="Description"
                            defaultValue={asset?.assetDescription ?? ''}
                            onChange={(e: any) => { handleChange(e) }}
                            onError={setFieldError}
                            error={errors?.assetDescription}
                        />
                    </Form.Group>
                </BaseForm>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="white" className="border" onClick={handleModalClose}>Cancel</Button>
                <Parcel
                    mountParcel={mountRootParcel}
                    config={AsyncButton}
                    variant="primary"
                    loader={loader}
                    formId="assetForm">
                    Save
                </Parcel>
            </Modal.Footer>
        </Modal>
    );
}

const Asset = () => {
    const { dsoAppId } = useParams();
    const [reload, setReload] = useState(false);
    const [data, setData] = useState(undefined);
    const [modalShow, setModalShow] = useState(false);
    const [assetData, setAssetData] = useState<any>({ dsoAppId });
    const [{ dsoApplication }] = useDSOContext();

    useEffect(() => {
        BackendAPIService.getAllAssets(dsoAppId).then(data => {
            setData(data);
            setReload(false);
        });
    }, [reload]);

    return (
        <>
            <div className="row pt-3 pb-3">
                <Parcel
                    mountParcel={mountRootParcel}
                    config={Breadcrumb}
                    wrapWith="div"
                    wrapClassName='col'
                    links={[
                        ...MyApplications,
                        {
                            name: dsoApplication?.projectInfoDetails?.projectName,
                            url: `/dsoeportal/viewapp/${dsoAppId}`
                        },
                        {
                            name: 'Expiring Assets'
                        }
                    ]}
                ></Parcel>
                <div className="col-12 col-sm-6 col-lg-4 text-end">
                    <a className="btn btn-primary float-right" type="button" onClick={() => {
                        setAssetData({ dsoAppId });
                        setModalShow(true)
                    }} >Add an Asset</a>
                </div>
            </div>
            <AddAssetModal
                onUpdateAsset={() => {
                    setReload(true);
                    setModalShow(false);
                }}
                assetData={assetData}
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
            <br></br>
            <div className="row">
                <div className="col">
                    <div className="bg-white pt-3 pb-3">
                        <h5 className="page-title ps-3">Expiring Assets</h5>
                        <div className="row">
                            <div className="col">
                                <div className="table-container">
                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th style={{ width: '15%' }}>Name</th>
                                                <th style={{ width: '10%' }}>Asset Type</th>
                                                <th style={{ width: '10%' }}>Environment</th>
                                                <th style={{ width: '55%' }}>Description</th>
                                                <th style={{ width: '10%' }}>Expiration Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data ? data.map((asset: any, index: number) =>
                                            (
                                                <tr key={index}>
                                                    <td>
                                                        <a href={undefined} onClick={() => {
                                                            setAssetData({
                                                                dsoAppId,
                                                                assetName: asset.assetName,
                                                                assetType: asset.assetType,
                                                                expirationDate: asset.expirationDate,
                                                                environmentType: asset.environmentType,
                                                                assetDescription: asset.assetDescription,
                                                                applicationId: asset.applicationId
                                                            });
                                                            setModalShow(true)
                                                        }} className="cursor-pointer">{asset.assetName}</a>
                                                    </td>
                                                    <td>{asset.assetType}</td>
                                                    <td>{asset.environmentType}</td>
                                                    <td>{asset.assetDescription}</td>
                                                    <td>{DateUtil.toDisplayDate(asset.expirationDate)}</td>
                                                </tr>
                                            )
                                            ) : (
                                                <tr><td colSpan={5}>No Assets found</td></tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Asset;