import { BaseService } from "./base.service";

class FortifyAPIService extends BaseService {
    protected apiCode: string = process.env.FORTIFY_API_CODE;

    constructor() {
        super();
        this.baseUrl = process.env.FORTIFY_API_URL;
    }

    public async createFortifyProfile(fortifyProfile: any): Promise<any> {
        let url = `${this.baseUrl}/api/CreateFortifyProfile?code=${this.apiCode}`;
        try {
            let res = await this.post(url, fortifyProfile);
            return res;
        } catch(e) {
            console.error(e);
        }
    }

    public async createServiceNowTicket(ticketDetails: any): Promise<any> {
        let url = `${process.env.SERVICE_NOW_API_URL}/api/CreateRequest?code=${process.env.SERVICE_NOW_API_CODE}`;
        try {
            let res = await this.post(url, ticketDetails);
            return res;
        } catch(e) {
            console.error(e);
        }
    }
}

const FortifyService = new FortifyAPIService();

export default FortifyService;