import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { mountRootParcel } from 'single-spa';
import Parcel from "single-spa-react/parcel";
import { Form, Row } from "react-bootstrap";
import { components } from '@deloitte-global-cloud-services//dsoe-portal-core';
import { MyApplications } from "../application/breadcrumb-links";
import { useDSOContext } from "../../context/DataLayer";
import BackendAPIService from "../../services/backendapi.service";
import { SanitizedLink } from "../application/ApplicationHome";

const { Breadcrumb, Loader, Select, Pagination } = components;

const ManageSSDLC = () => {

    const { dsoAppId } = useParams();
    const [loading, setLoading] = useState(true);
    const [selectedCheckpoint, setSelectedCheckpoint] = useState("");
    const [selectedStatus, setSelectedStatus] = useState("");
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [requirements, setRequirements] = useState([]);
    const [{ dsoApplication }] = useDSOContext();
    const [checkpointOptions] = useState([
        {
            label: "All",
            value: ""
        }
    ]);
    const [statusOptions] = useState([
        {
            label: "All",
            value: ""
        }
    ]);

    useEffect(() => {
        setLoading(true);
        async function getData() {
            await BackendAPIService.getAllRequirements(dsoAppId).then(data => {
                setData(data);
                setFilteredData(data);
                setRequirements(data?.slice(0, 10));
                data?.map(item => {
                    if (item.status && !statusOptions.some(option => option.value === item.status))
                        statusOptions.push({ label: item.status, value: item.status });
                    if (item.checkpointName && !checkpointOptions.some(option => option.value === item.checkpointName))
                        checkpointOptions.push({ label: item.checkpointName, value: item.checkpointName })
                });
            });
        }
        getData();
        setLoading(false);
    }, []);

    useEffect(() => {
        setLoading(true);
        const requirements = data.filter(item =>
            ((selectedCheckpoint == "" || (item.checkpointName === selectedCheckpoint)) && (selectedStatus == "" || (item.status === selectedStatus)))
        );
        setFilteredData(requirements);
        setRequirements(requirements?.slice(0, 10));
        setLoading(false);
    }, [filter]);

    return (
        <>
            <div className="row pt-3 pb-3">
                <Parcel
                    mountParcel={mountRootParcel}
                    config={Breadcrumb}
                    wrapWith="div"
                    wrapClassName='col'
                    links={[
                        ...MyApplications,
                        {
                            name: dsoApplication?.projectInfoDetails?.projectName,
                            url: `/dsoeportal/viewapp/${dsoAppId}`
                        },
                        {
                            name: 'SSDLC'
                        }
                    ]}
                ></Parcel>
            </div>
            <Row>
                <div className="col">
                    <div className="bg-white pt-3 pb-3">
                        <div className="row">
                            <div className="col-6">
                                <h5 className="page-title col-6 ps-3">SSDLC Compliance</h5>
                            </div>
                            <div className="col-6">
                                <Row className="pe-3">
                                    <Form.Label className="col-3 col-form-label text-end">Checkpoint</Form.Label>
                                    <Parcel
                                        eleName="checkpointType"
                                        required
                                        defaultValue={selectedCheckpoint}
                                        onChange={(selected) => {
                                            setSelectedCheckpoint(selected.value);
                                            setFilter(!filter);
                                        }}
                                        mountParcel={mountRootParcel}
                                        config={Select}
                                        options={checkpointOptions}
                                        wrapClassName="col-4"
                                    ></Parcel>
                                    <Form.Label className="col-1 col-form-label text-end">Status</Form.Label>
                                    <Parcel
                                        eleName="statusType"
                                        required
                                        defaultValue={selectedStatus}
                                        onChange={(selected) => {
                                            setSelectedStatus(selected.value)
                                            setFilter(!filter);
                                        }}
                                        mountParcel={mountRootParcel}
                                        config={Select}
                                        options={statusOptions}
                                        wrapClassName="col-4"
                                    >
                                    </Parcel>
                                </Row>
                            </div>
                        </div>
                        <div className="row ps-3 pe-3 pt-3">
                            <div className="col">
                                <div className="alert alert-warning">
                                    This functionality is to help project teams track their SSDLC compliance progress. It does not in any way guarantee fulfillment of the Deloitte SSDLC process.
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="table-container">
                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th style={{ width: '10%' }}>CheckPoint</th>
                                                <th style={{ width: '15%' }}>Section ID</th>
                                                <th style={{ width: '15%' }}>Implementation Level</th>
                                                <th style={{ width: '10%' }}>Security ID</th>
                                                <th style={{ width: '35%' }}>Requirement</th>
                                                <th style={{ width: '10%' }}>Status</th>
                                                <th style={{ width: '5%' }}>View Requirement</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {loading ? (
                                                <tr>
                                                    <td colSpan={6}>
                                                        <Parcel config={Loader} mountParcel={mountRootParcel} />
                                                    </td>
                                                </tr>
                                            ) : (requirements.length ? requirements?.map((item: any, index: number) => {
                                                return (<tr key={index}>
                                                    <td>{item.checkpointName}</td>
                                                    <td>{item.sectionId}</td>
                                                    <td>{item.implementationLevel}</td>
                                                    <td>{item.securityId}</td>
                                                    <td>{item.requirement}</td>
                                                    <td>{item.status}</td>
                                                    <td className="text-center">
                                                        <SanitizedLink prefix={"/ssdlccompliance"} id={dsoApplication?.projectInfoDetails?.dsoAppId} suffix={`/${item.securityId}`} className="text-decoration-none" state={{ requirementId: item.id, requirementDesc: item.requirement }}>
                                                            <img className="action_icon" src="/assets/images/icon-eye@2x.png"/>
                                                        </SanitizedLink>
                                                       
                                                    </td>
                                                </tr>)
                                            }) : (
                                                <tr>
                                                    <td colSpan={7}>
                                                        No SSDLC Compliance found
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {filteredData?.length > 0 && (
                            <Parcel
                                mountParcel={mountRootParcel}
                                className="float-end"
                                totalRows={filteredData.length}
                                onChangePage={(startIndex: number, pageSize: number) => setRequirements(filteredData?.slice(startIndex, startIndex + pageSize))}
                                config={Pagination}
                                wrapWith="div"
                            ></Parcel>
                        )}
                    </div>
                </div>
            </Row>
            <div className="col-12 col-sm-6 col-lg-12 text-start mt-3">
                <SanitizedLink prefix={"/viewapp/"} id={dsoApplication?.projectInfoDetails?.dsoAppId} suffix={""}  className="btn btn-sm btn-outline-primary pl-3 pr-3">
                Back
                </SanitizedLink>
               
            </div>
        </>
    );
}

export default ManageSSDLC;