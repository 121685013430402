import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, Form, Row, Col, Button, Card } from "react-bootstrap";
import { components } from '@deloitte-global-cloud-services//dsoe-portal-core';
import Parcel from "single-spa-react/parcel";
import { mountRootParcel } from 'single-spa';
import uuid4 from "uuid4";
import AppRegistrationService from "../../services/appreg.service";
import { UserSelect } from "../../components/UserSelect";
import { alertMessage, showMessageOnPopup } from "../../components/MessageAlert";
import { Form as BaseForm } from '../../components/Form'
import APIUtil from "../../utils/APIUtil";

const { Select, Button: AsyncButton, HelpText, TextField } = components;

const RegisterApp = (props: any) => {
    const navigate = useNavigate();
    const { onCloseModal, isUpdateModal, appData, onUpdate, ...restProps } = props;
    const [application, setApplication] = useState<Application>(appData);
    const [memberFirmsList, setMemberFirmsList] = useState([]);
    const [bussinessAreasList, setBussinessAreasList] = useState([]);
    const [functionGroupsList, setFunctionGroupsList] = useState([]);
    const [errors, setErrors] = useState<any>();
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        if (props.show) {
            AppRegistrationService.getMemberFirms().then(data => {
                setMemberFirmsList(data);
            });
            AppRegistrationService.getFunctionGroups().then(data => {
                setFunctionGroupsList(data);
            });
            if (application?.projectInfoDetails?.memberFirmCode === 'US') AppRegistrationService.getBusinessAreas(application.projectInfoDetails?.function).then(data => setBussinessAreasList(data));
        }
    }, [props.show]);

    const handleChange = (event: any, nestedProperty: string) => {
        setValue(event.target.value, nestedProperty, event.target.name);
    };

    const setValue = (value: string | number, nestedProperty: string, name: string) => {
        setApplication({
            ...application, [nestedProperty]: {
                ...application[nestedProperty],
                [name]: value
            }
        });
    };

    const handleModalClose = () => {
        onCloseModal();
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        event.persist()
        let response: any;
        const app = { ...application, devConfigDetails: { repoDetails: [], organization: process.env.GITHUB_ORGANIZATION } };
        let message = 'Application was registered successfully. Please check you email for DSOappId information.';
        if (app?.projectInfoDetails?.dsoAppId) {
            message = 'Application was updated successfully.';
            response = await AppRegistrationService.updateProject(app);
        } else {
            app.projectInfoDetails.cosmicAppId = uuid4();
            response = await AppRegistrationService.createProject(app);
            if (response.success) navigate(`/viewapp/${response.data.dsoAppId}`);
        }
        if (response?.success) {
            alertMessage({ message });
            onUpdate();
            handleModalClose();
        } else {
            showMessageOnPopup({
                message: `Application registration failed. ${APIUtil.getErrorMessageLink(response?.error?.details)}`
            });
        }
    }

    const setFieldError = (name, error) => {
        setErrors({ ...errors, [name]: error });
    }
    const apmIDTooltip = <span>APM Product to be registered in the (APM: Application Portfolio Management) Business Application table. If you don't have a APM ID for your application, click <a target="_blank" href="https://deloitteus.service-now.com/sp?id=sc_cat_item&sys_id=75c3871adb9e3c50eb90abc5ca9619ab">here</a> to request one</span>

    return (
        <Modal {...restProps} onHide={handleModalClose} backdrop="static" className="modal-right" aria-labelledby="contained-modal-title-vcenter" size="lg">
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter" className="page-title">{application?.projectInfoDetails?.dsoAppId ? 'Update' : 'Register'} an Application</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <BaseForm setLoader={setLoader} errors={errors} onError={setErrors} id='appRegForm' onSubmit={handleSubmit}>
                    <Row>
                        <Card className="border-0 mb-4 ">
                            <h5 className="page-title ps-3">Organization</h5>
                            <Card.Body>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="6" controlId="memberFirmSelector">
                                        <Form.Label>
                                            Member Firm
                                            <span className="text-danger">*</span>
                                        </Form.Label>
                                        <Parcel
                                            eleName="memberFirmCode"
                                            required
                                            errorMessage="Please select member firm.!"
                                            onChange={(selected) => {
                                                setValue(selected.value, "projectInfoDetails", "memberFirmCode");
                                                if (selected.value === 'US') AppRegistrationService.getBusinessAreas(application.projectInfoDetails?.function).then(data => setBussinessAreasList(data));
                                                else setBussinessAreasList([]);
                                            }}
                                            defaultValue={application.projectInfoDetails?.memberFirmCode}
                                            mountParcel={mountRootParcel}
                                            config={Select}
                                            options={memberFirmsList}
                                        ></Parcel>
                                    </Form.Group>
                                    <Form.Group as={Col} md="6" controlId="functionSelector">
                                        <Form.Label>
                                            Function/Group<span className="text-danger">*</span>
                                        </Form.Label>
                                        <Parcel
                                            eleName="function"
                                            required
                                            defaultValue={application?.projectInfoDetails?.function}
                                            errorMessage="Please select function.!"
                                            mountParcel={mountRootParcel}
                                            config={Select}
                                            onChange={(selected) => {
                                                if (application.projectInfoDetails?.memberFirmCode === 'US') AppRegistrationService.getBusinessAreas(selected.value).then(data => setBussinessAreasList(data));
                                                else setBussinessAreasList([]);
                                                setValue(selected.value, "projectInfoDetails", "function")
                                            }}
                                            options={functionGroupsList}
                                        ></Parcel>
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group as={Col} md="6" controlId="businessAreaSelector">
                                        <Form.Label>
                                            Business Area
                                            {bussinessAreasList?.length > 0 && <span className="text-danger">*</span>}
                                        </Form.Label>
                                        <Parcel
                                            eleName="businessArea"
                                            required={bussinessAreasList?.length}
                                            defaultValue={bussinessAreasList?.length ? application?.projectInfoDetails?.businessArea : undefined}
                                            onChange={(selected) => { setValue(selected.value, "projectInfoDetails", "businessArea") }}
                                            errorMessage="Please select business area.!"
                                            mountParcel={mountRootParcel}
                                            config={Select}
                                            options={bussinessAreasList}
                                        ></Parcel>
                                    </Form.Group>
                                    <Form.Group as={Col} md="6" controlId="devHubNameSelector">
                                        <Form.Label>
                                            DevHub Name<span className="text-danger">*</span>
                                            <Parcel config={HelpText} mountParcel={mountRootParcel} wrapWith="span" text="Identifies the development group that will own the application." />
                                        </Form.Label>
                                        <Parcel
                                            eleName="devHub"
                                            required
                                            defaultValue={application?.projectInfoDetails?.devHub}
                                            errorMessage="Please select DevHub name.!"
                                            mountParcel={mountRootParcel}
                                            config={Select}
                                            onChange={(selected) => { setValue(selected.value, "projectInfoDetails", "devHub") }}
                                            options={[
                                                { value: '', label: 'Select' },
                                                { value: 'DAS', label: 'Deloitte Application Studio' },
                                                { value: 'GDAS', label: 'Global Deloitte Application Studio' },
                                                { value: 'GCS', label: 'Global Cloud Services' },
                                                { value: 'AGPS', label: 'Advisory-GPS' }
                                            ]}
                                        ></Parcel>
                                    </Form.Group>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Row>
                    <Row >
                        <Card className="border-0 mb-4 ">
                            <h5 className="page-title ps-3">Project Details</h5>
                            <Card.Body>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="6" controlId="projectNameText">
                                        <Form.Label>
                                            DSO Application / Product Name<span className="text-danger">*</span>
                                            <Parcel config={HelpText} mountParcel={mountRootParcel} wrapWith="span" text="The name of the application/product to be registered as a DSO application/product." />
                                        </Form.Label>
                                        <Parcel
                                            config={TextField}
                                            mountParcel={mountRootParcel}
                                            id="projectName"
                                            required
                                            minLength={8}
                                            maxLength={100}
                                            type="text"
                                            eleName="projectName"
                                            data-display="Project Name"
                                            defaultValue={application?.projectInfoDetails?.projectName ?? ''}
                                            readOnly={props.repositoryName}
                                            disabled={props.repositoryName}
                                            onChange={(e) => { handleChange(e, 'projectInfoDetails') }}
                                            onError={setFieldError}
                                            error={errors?.projectName}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="6" controlId="technicalAdminText">
                                        <Form.Label>
                                            DSO Technical Administrator<span className="text-danger">*</span>
                                            <Parcel config={HelpText} mountParcel={mountRootParcel} wrapWith="span" text="The name of the person who is the administrator of the DSO registered application." />
                                        </Form.Label>
                                        <UserSelect
                                            required
                                            placeholder="Search User"
                                            onChange={(selected) => {
                                                setApplication({
                                                    ...application,
                                                    projectInfoDetails: {
                                                        ...application?.projectInfoDetails,
                                                        technicalAdmin: selected.value
                                                    }
                                                });
                                            }}
                                            defaultValue={application?.projectInfoDetails?.technicalAdmin}
                                            elementName="technicalAdmin" />
                                    </Form.Group>
                                </Row>

                                <Row className="mb-3">
                                    <Form.Group as={Col} md="6" controlId="primaryContact">
                                        <Form.Label>
                                            Primary Contact<span className="text-danger">*</span>
                                        </Form.Label>
                                        <UserSelect
                                            placeholder="Search User"
                                            required
                                            onChange={(selected) => {
                                                setApplication({
                                                    ...application,
                                                    projectInfoDetails: {
                                                        ...application?.projectInfoDetails,
                                                        primaryContact: selected.value
                                                    }
                                                });
                                            }}
                                            defaultValue={application?.projectInfoDetails?.primaryContact}
                                            elementName="primaryContact" />
                                    </Form.Group>
                                    <Form.Group as={Col} md="6" controlId="secondaryContact">
                                        <Form.Label>
                                            Secondary Contact
                                        </Form.Label>
                                        <UserSelect
                                            placeholder="Search User"
                                            onChange={(selected) => {
                                                setApplication({
                                                    ...application,
                                                    projectInfoDetails: {
                                                        ...application?.projectInfoDetails,
                                                        secondaryContact: selected.value
                                                    }
                                                });
                                            }}
                                            defaultValue={application?.projectInfoDetails?.secondaryContact}
                                            elementName="secondaryContact" />
                                    </Form.Group>
                                </Row>

                                <Row className="mb-3">
                                    <Form.Group as={Col} md="6" controlId="chargeCodeText">
                                        <Form.Label>
                                            Billing / Charge Code<span className="text-danger">*</span>
                                            <Parcel config={HelpText} mountParcel={mountRootParcel} wrapWith="span" text="The billing/charge code used to charge all DSO related costs for this project." />
                                        </Form.Label>
                                        <Parcel
                                            config={TextField}
                                            mountParcel={mountRootParcel}
                                            id="chargeCode"
                                            required
                                            type="text"
                                            eleName="chargeCode"
                                            data-display="Charge Code"
                                            defaultValue={application?.projectInfoDetails?.chargeCode ?? ''}
                                            readOnly={props.repositoryName}
                                            disabled={props.repositoryName}
                                            onChange={(e) => { handleChange(e, 'projectInfoDetails') }}
                                            onError={setFieldError}
                                            error={errors?.chargeCode}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="6" controlId="assetIdText">
                                        <Form.Label>
                                            APM ID<span className="text-danger">*</span>
                                            <Parcel config={HelpText} mountParcel={mountRootParcel} wrapWith="span" text={apmIDTooltip}/>
                                        </Form.Label>
                                        <Parcel
                                            config={TextField}
                                            mountParcel={mountRootParcel}
                                            required
                                            id="assetId"
                                            type="text"
                                            eleName="assetId"
                                            data-display="APM ID"
                                            defaultValue={application?.projectInfoDetails?.assetId ?? ''}
                                            readOnly={props.repositoryName}
                                            disabled={props.repositoryName}
                                            onChange={(e) => { handleChange(e, 'projectInfoDetails') }}
                                            onError={setFieldError}
                                            error={errors?.assetId}
                                        />
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} controlId="projectDescriptionText">
                                        <Form.Label>
                                            DSO Application / Product Description<span className="text-danger">*</span>
                                            <Parcel config={HelpText} mountParcel={mountRootParcel} wrapWith="span" text="The description of the application/product to be registered as a DSO application/product." />
                                        </Form.Label>
                                        <Parcel
                                            config={TextField}
                                            mountParcel={mountRootParcel}
                                            id="projectDescription"
                                            required
                                            maxLength={500}
                                            rows={3}
                                            as="textarea"
                                            eleName="projectDescription"
                                            data-display="Project Description"
                                            defaultValue={application?.projectInfoDetails?.projectDescription ?? ''}
                                            readOnly={props.repositoryName}
                                            disabled={props.repositoryName}
                                            onChange={(e) => { handleChange(e, 'projectInfoDetails') }}
                                            onError={setFieldError}
                                            error={errors?.projectDescription}
                                        />
                                    </Form.Group>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Row>
                </BaseForm>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="white" className="border" onClick={handleModalClose}>Cancel</Button>
                <Parcel
                    mountParcel={mountRootParcel}
                    config={AsyncButton}
                    variant="primary"
                    loader={loader}
                    formId="appRegForm"
                >
                    {application?.projectInfoDetails?.dsoAppId ? 'Save' : 'Register'}
                </Parcel>
            </Modal.Footer>
        </Modal>
    );
}

export default RegisterApp;
