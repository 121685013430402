import { useEffect, useState } from "react";
import { useParams, Link, useLocation } from "react-router-dom";
import { mountRootParcel } from 'single-spa';
import Parcel from "single-spa-react/parcel";
import { Modal, Form, Row, Col, InputGroup, Button } from "react-bootstrap";
import { components } from '@deloitte-global-cloud-services//dsoe-portal-core';
import { MyApplications } from "../application/breadcrumb-links";
import { alertMessage, showMessageOnPopup } from "../../components/MessageAlert";
import { useDSOContext } from "../../context/DataLayer";
import BackendAPIService from "../../services/backendapi.service";
import { Form as BaseForm } from '../../components/Form';
import APIUtil from "../../utils/APIUtil";
import { SanitizedLink } from "../application/ApplicationHome";

const { Breadcrumb, Loader, TextField, Button: AsyncButton, Pagination } = components;

const StatementStatus = {
    Met: 1,
    NotMet: 2
}

const RequirementStatus = {
    NotStarted: 1,
    InProgress: 2,
    Completed: 3
}

const StatementDetails = (props: any) => {

    const { dsoAppId, requirementId, allStatements, handleModalClose, onUpdateStatementDetails, ...restProps } = props;
    const [statement, setStatement] = useState<any>();
    const [loader, setLoader] = useState(false);
    const [errors, setErrors] = useState<any>();

    useEffect(() => {
        const stmt = props.statement;
        if (stmt.statusId === 0) stmt.statusId = StatementStatus.NotMet;
        setStatement(stmt);
    }, [props.statement]);

    const handleSubmit = async () => {
        // console.log(statement.documentData);
        // let uploadData = {
        //     repoName : 'test-file-upload',
        //     buildVersion : '0.0.1',
        //     dsoAppId : dsoAppId,
        //     file : statement.documentData
        // };
        // let response = await BackendAPIService.uploadDocument(uploadData);
        // if(response.success) {
        let response = await BackendAPIService.updateStatement(statement);
        if (response.success) {
            let stmts = allStatements.filter((stmt: any) => {
                return ((stmt.id === statement.id && statement.statusId === StatementStatus.Met) || (stmt.id !== statement.id && stmt.statusId === StatementStatus.Met))
            });
            const isAllMet = stmts?.length === allStatements.length;
            stmts = allStatements.filter((stmt: any) => {
                return ((stmt.id === statement.id && statement.statusId !== StatementStatus.Met) || (stmt.id !== statement.id && stmt.statusId !== StatementStatus.Met))
            });
            const isAllNotMet = stmts?.length === allStatements.length;
            const requirementStatus = isAllMet ? RequirementStatus.Completed : isAllNotMet ? RequirementStatus.NotStarted : RequirementStatus.InProgress;
            response = await BackendAPIService.updateRequirementStatus(dsoAppId, requirementId, requirementStatus);
        }
        if (response.success) {
            alertMessage({ message: 'Statement was updated successfully.' });
            handleModalClose(true);
        } else {
            showMessageOnPopup({
                message: `Statement Update was failed. ${APIUtil.getErrorMessageLink(JSON.stringify(response?.error))}`
            });
        }
        setLoader(false);
    }

    const setFieldError = (name, error) => {
        setErrors({ ...errors, [name]: error });
    }

    return (
        <Modal {...restProps} onHide={handleModalClose} backdrop="static" className="modal-right" size="lg">
            <Modal.Header closeButton >
                <Modal.Title id="contained-modal-title-vcenter">
                    Statement Details
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <BaseForm setLoader={setLoader} errors={errors} onError={setErrors} id='statementForm' onSubmit={handleSubmit}>
                    <Row className="mb-3">
                        <Row>
                            <Col lg="4"><p>Section ID</p></Col>
                            <Col lg="8"><p>{statement?.sectionId}</p></Col>
                        </Row>
                        <Row>
                            <Col lg="4"><p>Statement ID</p></Col>
                            <Col lg="8"><p>{statement?.statementsId}</p></Col>
                        </Row>
                        <Row>
                            <Col lg="4"><p>Implementation Level</p></Col>
                            <Col lg="8"><p>{statement?.implementationLevel}</p></Col>
                        </Row>
                        <Row>
                            <Col lg="4"><p>Description</p></Col>
                            <Col lg="8"><p>{statement?.statement}</p></Col>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Row} md="6">
                                <Col lg="4">
                                    <Form.Label>
                                        Statement being met?
                                    </Form.Label>
                                </Col>
                                <Col lg="8">
                                    <InputGroup>
                                        <Form.Check
                                            required
                                            id="newRepo"
                                            name="isRepoExists"
                                            inline
                                            type="radio"
                                            label="Yes"
                                            checked={statement?.statusId === 1}
                                            onChange={(e) => setStatement({ ...statement, statusId: 1, status: 'Met' })}
                                        />
                                        <Form.Check
                                            required
                                            id="existingRepo"
                                            name="isRepoExists"
                                            inline
                                            type="radio"
                                            label="No"
                                            checked={statement?.statusId === 2}
                                            onChange={(e) => setStatement({ ...statement, statusId: 2, status: 'Not Met' })}
                                        />
                                    </InputGroup>
                                </Col>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Row} md="6" controlId="projectNameText">
                                <Col lg="4">
                                    <Form.Label as={Col} lg="6">Documentation</Form.Label>
                                </Col>
                                <Col lg="8">
                                    <Parcel
                                        config={TextField}
                                        mountParcel={mountRootParcel}
                                        required
                                        defaultValue={statement?.documentUrl ?? ''}
                                        onChange={(e: any) => { setStatement({ ...statement, documentUrl: e.target.value }) }}
                                        type="url"
                                        eleName="documentUrl"
                                        onError={setFieldError}
                                        error={errors?.documentUrl}
                                    />
                                </Col>
                            </Form.Group>
                        </Row>
                        {/* <Row className="mb-3">
                            <Form.Group as={Row} md="6" controlId="projectNameText">
                                <Col lg="4">
                                    <Form.Label as={Col} lg="6">Documentation</Form.Label>
                                </Col>
                                <Col lg="8">
                                    <Parcel
                                        config={TextField}
                                        mountParcel={mountRootParcel}
                                        required
                                        type="file"
                                        onChange={(e: any) => { setStatement({...statement, documentData: e.target.files[0]}) }}
                                    />
                                </Col>
                            </Form.Group>
                        </Row> */}
                        <Row>
                            <Form.Group as={Row} md="6" controlId="projectNameText">
                                <Col lg="4">
                                    <Form.Label as={Col} lg="6">Document Description</Form.Label>
                                </Col>
                                <Col lg="8">
                                    <Parcel
                                        config={TextField}
                                        mountParcel={mountRootParcel}
                                        required
                                        onChange={(e: any) => { setStatement({ ...statement, documentDescription: e.target.value }) }}
                                        defaultValue={statement?.documentDescription ?? ''}
                                        as="textarea"
                                        eleName="documentDescription"
                                        onError={setFieldError}
                                        error={errors?.documentDescription}
                                    />
                                </Col>
                            </Form.Group>
                        </Row>
                    </Row>
                </BaseForm>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="white" className="border" onClick={handleModalClose}>Cancel</Button>
                <Parcel
                    mountParcel={mountRootParcel}
                    config={AsyncButton}
                    variant="primary"
                    loader={loader}
                    formId="statementForm">
                    Save
                </Parcel>
            </Modal.Footer>
        </Modal>
    );
}

const CHECKPOINTS = {
    1: 'CheckPoint I',
    2: 'CheckPoint II',
    3: 'CheckPoint III'
}

const RequirementDetails = (props: any) => {

    const { dsoAppId } = useParams();
    const { securityId } = useParams();
    const location = useLocation();
    const { requirementId, requirementDesc } = location.state
    const [reload, setReload] = useState(false);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [statements, setStatements] = useState([]);
    const [selectedStatement, setSelectedStatement] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [{ dsoApplication }] = useDSOContext();

    useEffect(() => {
        BackendAPIService.getAllStatements(dsoAppId, securityId).then(data => {
            setData(data);
            setStatements(data.slice(0, 10));
            setLoading(false);
        });
    }, [reload]);

    const showStatementDetails = (statement: any) => {
        setSelectedStatement(statement);
        setShowModal(true);
    }

    return (
        <>
            <div className="row pt-3 pb-3">
                <Parcel
                    mountParcel={mountRootParcel}
                    config={Breadcrumb}
                    wrapWith="div"
                    wrapClassName='col'
                    links={[
                        ...MyApplications,
                        {
                            name: dsoApplication?.projectInfoDetails?.projectName,
                            url: `/dsoeportal/viewapp/${dsoAppId}`
                        },
                        {
                            name: "SSDLC Compliance",
                            url: `/dsoeportal/ssdlccompliance/${dsoAppId}`
                        },
                        {
                            name: `Requirement Details`,
                        }
                    ]}
                ></Parcel>
            </div>
            <StatementDetails
                dsoAppId={dsoAppId}
                requirementId={requirementId}
                allStatements={data}
                statement={selectedStatement}
                show={showModal}
                handleModalClose={(_reload?: boolean) => {
                    setShowModal(false);
                    if (_reload) setReload(!reload);
                }}
            />
            <Row>
                <div className="col">
                    <div className="bg-white pt-3 pb-3">
                        <h5 className="page-title ps-3">Requirement Details</h5>
                        <div className="row">
                            <div className="col">
                                <div className="card-text text-secondary">
                                    <div className="ps-3 pt-3 pb-3">
                                        <span className="fw-bold">{securityId} : </span>
                                        <span className="pl-2">{requirementDesc}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="table-container">
                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th style={{ width: '20%' }}>Section ID</th>
                                                <th style={{ width: '15%' }}>Statement ID</th>
                                                <th style={{ width: '35%' }}>Statement</th>
                                                <th style={{ width: '10%' }}>Status</th>
                                                <th style={{ width: '10%' }}>Checkpoint</th>
                                                <th style={{ width: '10%' }}>View Statement</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {loading ? (
                                                <tr>
                                                    <td colSpan={6}>
                                                        <Parcel config={Loader} mountParcel={mountRootParcel} />
                                                    </td>
                                                </tr>
                                            ) : (
                                                statements.map((statement, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{statement.sectionId}</td>
                                                            <td>{statement.statementsId}</td>
                                                            <td>{statement.statement}</td>
                                                            <td>{statement.status}</td>
                                                            <td>{CHECKPOINTS[statement.checkpointId]}</td>
                                                            <td className="text-center">
                                                                <a href={undefined} onClick={() => showStatementDetails(statement)}>
                                                                    <img className="action_icon" src="/assets/images/icon-eye@2x.png" />
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {data?.length && (
                            <Parcel
                                mountParcel={mountRootParcel}
                                className="float-end"
                                totalRows={data.length}
                                onChangePage={(startIndex: number, pageSize: number) => setStatements(data.slice(startIndex, startIndex + pageSize))}
                                config={Pagination}
                                wrapWith="div"
                            ></Parcel>
                        )}
                    </div>
                </div>
            </Row>
            <div className="col-12 col-sm-6 col-lg-12 text-start mt-3">
                <SanitizedLink prefix={"/ssdlccompliance"} id={dsoApplication?.projectInfoDetails?.dsoAppId} suffix={""} className="btn btn-sm btn-outline-primary pl-3 pr-3">
                    Back
                </SanitizedLink>

            </div>
        </>
    );
}

export default RequirementDetails;