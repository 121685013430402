//@ts-nocheck

import { BrowserRouter, Routes, Route } from "react-router-dom"
import Parcel from 'single-spa-react/parcel';
import { mountRootParcel } from 'single-spa';
import ListApplication from "./pages/application/ListApplication";
import ApplicationHome from "./pages/application/ApplicationHome";
import ManageRepositories from "./pages/application/ManageRepositories";
import CompianceRecords from "./pages/application/CompianceRecords";
import BuildHistory from "./pages/application/BuildHistory";
import ReleaseHistory from "./pages/application/ReleaseHistory";
import ScanResults from "./pages/application/ScanResults";
import Asset from "./pages/application/Asset";
import ManageMilestones from "./pages/application/ManageMilestones";
import  ManageScanProfiles from "./pages/scanprofiles/ManageScanProfiles"
import ManageSSDLC from "./pages/ssdlc/ManageSSDLC";
import {AuthenticatedView} from "@deloitte-dsoe/portal-auth";
import RequirementDetails from "./pages/ssdlc/RequirementDetails";

import {DataLayer} from "./context/DataLayer"
import reducer , {initialState} from "./context/reducer";

const App = () => {

  return (
    <Parcel config={AuthenticatedView} mountParcel={mountRootParcel}>
      <DataLayer initialState={initialState} reducer={reducer}>
        <BrowserRouter basename="/dsoeportal">
          <Routes>
            <Route path="/" element={<ListApplication />} />
            <Route path="/viewapp/:dsoAppId" element={<ApplicationHome />} />
            <Route path="/managerepo/:dsoAppId" element={<ManageRepositories />} />
            <Route path="/compliancerecords/:dsoAppId" element={<CompianceRecords />} />
            <Route path="/buildhistory/:dsoAppId" element={<BuildHistory />} />
            <Route path="/releasehistory/:dsoAppId" element={<ReleaseHistory />} />
            <Route path="/scanresults/:dsoAppId" element={<ScanResults />} />
            <Route path="/assets/:dsoAppId" element={<Asset />} />
            <Route path="/viewapp/:dsoAppId/milestones" element={<ManageMilestones />} />
            <Route path="/scanprofiles/:dsoAppId/:repoName" element={<ManageScanProfiles />} />
            <Route path="/ssdlccompliance/:dsoAppId" element={<ManageSSDLC />} />
            <Route path="/ssdlccompliance/:dsoAppId/:securityId" element={<RequirementDetails />} />
          </Routes>
        </BrowserRouter>
      </DataLayer>
    </Parcel>
  );
};

export default App;