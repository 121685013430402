import axios from "axios";

export default class APIUtil {
  static token: { [key: string]: string } = {};

  static async downloadScanResult(data: any, isLog?: boolean): Promise<any> {
    const url = `${process.env.GENESIS_ARTIFACT_URL}/v1/DownloadArtifact`;
    const key = process.env.GENESIS_ARTIFACT_SUBSCRIPTIONKEY;
    const res = await axios.request({
        headers: {
            'Ocp-Apim-Subscription-Key': key
        },
        data,
        url,
        method: 'post',
        responseType: (data?.scanEngine === 'sonarqube' && !isLog) ? 'text' : 'blob'
    });
    return res.data;
  }

  static getErrorMessageLink(errorMessage: string, errorCode?: string): string {
    return `\nPlease contact support team using this <a href="mailto:dsoe@deloitte.com" target="_top">LINK</a>. Make sure to inform the below error message for faster response.${errorCode && '\nError Code: ' + errorCode}\nError Message: ${errorMessage}`;
  }

  static concatEmails(emails: Array<string | undefined>): string {
    let concatEmails = "";
    emails?.forEach(email => {
      if(concatEmails.length > 0) {
        concatEmails += ',';
      }
      concatEmails += email;
    })
    return concatEmails;
  }
}