import DataTable from "react-data-table-component";
import styled from "styled-components";

const CustomDataTable = styled(DataTable)`
    .rdt_Table  {
        --bs-table-hover-bg: rgba(248, 249, 250, 1);
    }

    .rdt_TableRow:hover {
        background-color:#f8f9fa!important;
    }

    .rdt_TableRow {
        font-size: 1rem;
        
    }

    .rdt_TableHead {
        color:#0b848e;
        font-size: 1rem;
        font-weight: bold;
    }

    .rdt_TableCol * {
        word-wrap: break-word;
        white-space: normal;
    }
`;

const customStyles = {
    pagination: {
        style: {
            color: '#555 !important',
            fontSize: '1rem', // override the row height
            justifyContent: 'start !important'
        },
    },
};

export const CustomTable = (props: any) => {

    const { columns, data } = props;

    return (
        <>
            <CustomDataTable
                columns={columns}
                data={data}
                customStyles={customStyles}
                pagination
            />
        </>
    );
}
