import { Form, Row, Col, InputGroup } from "react-bootstrap";
import Parcel from "single-spa-react/parcel";
import { mountRootParcel } from 'single-spa';
import { components } from '@deloitte-global-cloud-services//dsoe-portal-core';
import { useEffect, useState } from "react";
import { UserSelect } from "../../components/UserSelect";
import moment from "moment";
import attributes from './fortify-attributes.json';
import { useDSOContext } from "../../context/DataLayer";
import FortifyService from "../../services/fortify.service";
import { showMessageOnPopup } from "../../components/MessageAlert";
import APIUtil from "../../utils/APIUtil";

const { HelpText, TextField, Select } = components;

const defaultProfile = {
    "projectVersion": {
        "name": null,
        "description": null,
        "active": true,
        "committed": false,
        "project": {
            "createdBy": "",
            "description": null,
            "issueTemplateId": "f8eed465-0eab-4535-bec9-f9b02093cc8d",
            "name": null
        },
        "issueTemplateId": "f8eed465-0eab-4535-bec9-f9b02093cc8d"
    },
    "attributeRequests": [
        {
            "attributeDefinitionId": 1,
            "value": null,
            "values": null,
            "guid": "BusinessRisk"
        },
        {
            "attributeDefinitionId": 8,
            "value": null,
            "values": null,
            "guid": "ProjectType"
        },
        {
            "attributeDefinitionId": 14,
            "value": null,
            "values": null,
            "guid": "BusinessUnit"
        },
        {
            "attributeDefinitionId": 16,
            "value": null,
            "values": null,
            "guid": "Region"
        },
        {
            "attributeDefinitionId": 30,
            "value": null,
            "values": null,
            "guid": "acf864a9-efaa-46f1-a1bc-cf43ca8b8adb"
        },
        {
            "attributeDefinitionId": 31,
            "value": null,
            "values": null,
            "guid": "e1f0ff9f-0191-4db0-aee0-01decb648a8b"
        },
        {
            "attributeDefinitionId": 32,
            "value": null,
            "values": null,
            "guid": "056aa47d-b130-454d-8005-b2d4cc2f7ba5"
        },
        {
            "attributeDefinitionId": 35,
            "value": null,
            "values": null,
            "guid": "0c0fb44e-c0f2-4b5d-8297-b0f999f2ba8b"
        },
        {
            "attributeDefinitionId": 36,
            "value": null,
            "values": null,
            "guid": "05272d1e-4080-4c5c-9051-2eef127e532c"
        },
        {
            "attributeDefinitionId": 52,
            "value": null,
            "values": null,
            "guid": "9dbd0510-8325-4c33-b160-e51765693131"
        },
        {
            "attributeDefinitionId": 53,
            "value": null,
            "values": null,
            "guid": "e320bcfa-80a9-4e3a-b513-e9b727a59dbe"
        }
    ]
}

if(process.env.IS_PROD) {
    defaultProfile.attributeRequests.push({
        "attributeDefinitionId": 55,
        "value": null,
        "values": null,
        "guid": "5086bc64-d8ff-49c2-a061-b277b3e8f477"
    });
    defaultProfile.attributeRequests.push({
        "attributeDefinitionId": 57,
        "value": null,
        "values": null,
        "guid": "0e2f3192-f580-4861-bf39-02d81ecec211"
    });
}

const Fortify = (props: any) => {

    const {repoName} = props;
    const [fortifyProfile, setFortifyProfile] = useState<any>({ ...defaultProfile, isProfileExists: true });
    const [isSASTProfile, setIsSASTProfile] = useState<boolean>(props?.profileDetails?.app_version !== undefined);
    const [isDASTProfile, setIsDASTProfile] = useState<boolean>(props?.profileDetails?.isDASTProfile);
    const [newSASTProfile, setNewSASTProfile] = useState<boolean>(false);
    const [newDASTProfile, setNewDASTProfile] = useState<boolean>(false);
    const [{ dsoApplication }] = useDSOContext();

    const getDeveloperEmails = (): Array<string> => {
        const emails: Array<string> = [];
        const repoDetails = dsoApplication?.devConfigDetails?.repoDetails?.filter(repo => repo.repoName === repoName);
        if(repoDetails?.developerDetails?.length > 0) emails.push(repoDetails?.developerDetails[0]);
        if(repoDetails?.developerDetails?.length > 1) emails.push(repoDetails?.developerDetails[1]);
        return emails;
    }

    const onModalClose = () => {
        setFortifyProfile({...defaultProfile});
    }

    const customValidation = async (profile: any): Promise<boolean> => {
        const noSASTProfile: any = document.getElementById("noSASTProfile");
        const isSASTDisabled = noSASTProfile?.checked;
        const noDASTProfile: any = document.getElementById("noDASTProfile");
        const isDASTDisabled = noDASTProfile?.checked;
        const inputSASTVersion:any = document.getElementById("app_version");
        const inputDASTVersion:any = document.getElementById("app_version_DAST");
        const isNewSAST = inputSASTVersion?.disabled;
        const isNewDAST = inputDASTVersion?.disabled;
        if(!isSASTDisabled && isNewSAST && !dsoApplication.projectInfoDetails.assetId) {
            props.setFieldError("app_version", "Missing APM ID. Please go to Application Details and enter it.");
            return true;
        }

        if(!isDASTDisabled && isNewDAST && !dsoApplication.projectInfoDetails.assetId) {
            props.setFieldError("app_version_DAST", "Missing APM ID. Please go to Application Details and enter it.");
            return true;
        }
        const appVersionSAST = `${dsoApplication.projectInfoDetails.projectName}-${dsoApplication.projectInfoDetails.assetId}-${repoName}-1-SAST`;
        const appVersionDAST = `${dsoApplication.projectInfoDetails.projectName}-${dsoApplication.projectInfoDetails.assetId}-${repoName}-1-DAST`;
        profile.scanProfiles[0].fortify_Scanprofile.scanProfileDetails[0].isDASTProfile = !isDASTDisabled;

        if(props.isEditable && isSASTDisabled) {
            delete profile.scanProfiles[0].fortify_Scanprofile.scanProfileDetails[0].app_version
        }
        if(props.isEditable && isDASTDisabled) {
            delete profile.scanProfiles[0].fortify_Scanprofile.scanProfileDetails[0].app_version_DAST
        }

        //APP-Name
        fortifyProfile.projectVersion.project.name = profile.scanProfiles[0].fortify_Scanprofile.scanProfileDetails[0].application;
        let failed: boolean = false;
        if (isNewSAST) {
            //SAST APP-Version
            fortifyProfile.projectVersion.name = appVersionSAST;
            profile.scanProfiles[0].fortify_Scanprofile.scanProfileDetails[0].app_version = appVersionSAST;
            const res: any = await createServiceNowTicket(profile);
            if(res?.success) {
                failed = await createFortifyProfile(getAttributeOptions(36)[0], res?.data?.message?.result?.number);
            }
        }
        if (isNewDAST && !failed) {
            //DAST APP-Version
            fortifyProfile.projectVersion.name = appVersionDAST;
            profile.scanProfiles[0].fortify_Scanprofile.scanProfileDetails[0].app_version_DAST = appVersionDAST;
            profile.scanProfiles[0].fortify_Scanprofile.scanProfileDetails[0].isDASTProfile = true;
            failed = await createFortifyProfile(getAttributeOptions(36)[1]);
        }
        return failed;
    }

    const createFortifyProfile = async (testingTechnique: any, requestNumber?: string): Promise<any> => {

        setFortifyAttribute(53, `dsoe-${dsoApplication.projectInfoDetails.dsoAppId}${requestNumber ? '-' + requestNumber : ''}`);
        setFortifyAttribute(55, dsoApplication.projectInfoDetails.function);
        setFortifyAttribute(57, dsoApplication.projectInfoDetails.assetId);
        setFortifyAttribute(36, [testingTechnique]);
        delete fortifyProfile.isProfileExists;
        const res = await FortifyService.createFortifyProfile(fortifyProfile);
        let failed = false;
        if (!res?.success) {
            failed = true;
            showMessageOnPopup({
                message: `Fortify Profile creation failed. ${APIUtil.getErrorMessageLink(res?.error?.message ?? res?.error, res?.error?.errorCode ?? res?.statusCode)}`
            });
        }
        return failed;
    }

    const createServiceNowTicket = async (profile: any): Promise<any> => {

        const authModule = await System.import('@deloitte-dsoe/portal-auth');
        const projectDetails = dsoApplication?.projectInfoDetails;
        const serviceNowReq = {
            dso_App_Id: dsoApplication.projectInfoDetails.dsoAppId,
            requested_For: authModule?.context?.value?.user?.email,
            fortify_SSC_Application_Name: profile.scanProfiles[0].fortify_Scanprofile.scanProfileDetails[0].application,
            fortify_SSC_Version_Name: fortifyProfile.projectVersion.name,
            fortify_Code_Contributors: `${APIUtil.concatEmails(getDeveloperEmails())}`,
            fortify_Normal_Users: `${APIUtil.concatEmails([projectDetails?.primaryContact, projectDetails?.secondaryContact, projectDetails?.technicalAdmin])}`
        };
        let res = await FortifyService.createServiceNowTicket(serviceNowReq);
        if (!res?.success) {
            showMessageOnPopup({
                message: `Service now request failed. ${APIUtil.getErrorMessageLink(res?.error?.message, res?.error?.errorCode)}`
            });
        }
        return res;
    }

    const getAttributeOptions = (attributeId: number): any[] => {
        const attribute: any = attributes[Object.keys(attributes).find(key => attributes[key].id === attributeId)];
        return attribute?.options;
    }

    const setFortifyAttribute = (attributeId: number, value: any) => {
        const attribute = fortifyProfile?.attributeRequests?.find(attr => attr.attributeDefinitionId === attributeId);
        if (attribute) Array.isArray(value) ? attribute.values = value : attribute.value = value;
    }

    const getAttribute = (attributeId: number): any => {
        const attribute = fortifyProfile?.attributeRequests?.find(attr => attr.attributeDefinitionId === attributeId);
        return attribute
    }

    const getAttributeValue = (attributeId: number): any => {
        const attribute = getAttribute(attributeId);
        return attribute?.value ? attribute?.value : attribute?.values
    }

    useEffect(() => {
        const memberAttributes = getAttributeOptions(14);
        const memberCode = memberAttributes?.find((attr: any) => attr.name === dsoApplication?.projectInfoDetails?.memberFirmCode)
        setFortifyAttribute(14, [memberCode]);
        setFortifyAttribute(35, dsoApplication?.projectInfoDetails?.chargeCode);
        setFortifyAttribute(30, moment(new Date()).format('YYYY-MM-DD'));
        setFortifyAttribute(52, dsoApplication.projectInfoDetails.devHub);
        props.setProfileDetails("application", `${dsoApplication.projectInfoDetails.memberFirmCode}-${dsoApplication.projectInfoDetails.function}-${getAttributeValue(52) ?? ''}`);
        props.setCustomValidation(() => customValidation);
        props.setModalClose(() => onModalClose);
    }, []);

    const riskHelpLink = <a target="_blank" href="https://resources.deloitte.com/sites/worldwidetechnology/gs/Documents/CIOC%20Application%20Security%20Risk%20Framework.pdf">Application Risk Levels</a>
    const dastCIIdentifierHelpText = <span>Token needed to run DAST scans from a pipeline. If you don't have a CI Identifier for your application, click <a target="_blank" href="https://deloitteglobal.service-now.com/sp?id=sc_cat_item&sys_id=edf4ee9937c90b0031119c9953990e88s">here</a> to request one</span>
    return (
        <>
            <Row>
                <Form.Group as={Col} md="6" className="mb-3">
                    <Form.Label>
                        Application Runtime<span className="text-danger">*</span>
                        <Parcel config={HelpText} mountParcel={mountRootParcel} wrapWith="span" text="Runtime version of the language used by the application, of the Repository." />
                    </Form.Label>
                    <Parcel
                        eleName="app_runtime"
                        required
                        defaultValue={props.profileDetails?.app_runtime}
                        errorMessage="Please select Application Runtime.!"
                        mountParcel={mountRootParcel}
                        config={Select}
                        onChange={(selected) => { props.setProfileDetails('app_runtime', selected.value) }}
                        options={[
                            { value: '', label: 'Select' },
                            { value: 'Python', label: 'Python' },
                            { value: 'Dotnet', label: 'Dotnet' },
                            { value: 'Java', label: 'Java' },
                            { value: 'JavaScript', label: 'JavaScript' },
                            { value: 'Default', label: 'Other' }
                        ]}
                    ></Parcel>
                </Form.Group>
                <Form.Group as={Col} md="6" className="mb-3">
                    <Form.Label>
                        Application Name<span className="text-danger">*</span>
                        <Parcel config={HelpText} mountParcel={mountRootParcel} wrapWith="span" text="Name of application or specific component being scanned" />
                    </Form.Label>
                    {(!newSASTProfile || !newDASTProfile) ? (
                        <Parcel
                            config={TextField}
                            mountParcel={mountRootParcel}
                            id="application"
                            required
                            maxLength={250}
                            type="text"
                            eleName="application"
                            data-display="Application Name"
                            defaultValue={props.profileDetails?.application || ''}
                            onChange={(e) => {
                                {
                                    props.setProfileDetails(e.target.name, e.target.value);
                                    fortifyProfile.profile.projectVersion.project.name = e.target.value;
                                }
                            }}
                            onError={props.setFieldError}
                            error={props.errors?.application}
                        />
                    ) : (
                        <Parcel
                            config={TextField}
                            mountParcel={mountRootParcel}
                            id="application"
                            disabled
                            readonly
                            type="text"
                            eleName="application"
                            data-display="Application Name"
                            value={`${dsoApplication.projectInfoDetails.memberFirmCode}-${dsoApplication.projectInfoDetails.function}-${getAttributeValue(52) ?? ''}`}
                        />
                    )}
                </Form.Group>
                {!props.isCopyProfile && (
                    <Form.Group as={Col} md="6" className="mb-3">
                        <Form.Label>
                            Do you want to associate a SAST profile?<span className="text-danger">*</span>
                        </Form.Label>
                        {props.isEditable ? (
                            <InputGroup className="mt-1">
                                <Form.Check
                                    required
                                    inline
                                    id="existingSASTProfile"
                                    type="radio"
                                    name="newProfile"
                                    label="Yes"
                                    checked={isSASTProfile && !newSASTProfile}
                                    onChange={(e) => {
                                        setIsSASTProfile(true);
                                        setNewSASTProfile(false);
                                        props.setProfileDetails('newSASTProfile', false);
                                        props.setProfileDetails("isServiceAccountAssociated", false);
                                    }}
                                />
                                <Form.Check
                                    required
                                    inline
                                    id="noSASTProfile"
                                    type="radio"
                                    name="newProfile"
                                    label="No"
                                    checked={!isSASTProfile}
                                    onChange={(e) => {
                                        e?.target?.parentElement?.classList?.remove("is-invalid");
                                        if(isDASTProfile) {
                                            setIsSASTProfile(false);
                                            props.setProfileDetails('newSASTProfile', false);
                                            props.setProfileDetails("isServiceAccountAssociated", true);
                                        } else {
                                            e?.target?.parentElement?.classList?.add("is-invalid");
                                            props.setFieldError("newSASTProfile", "Atlease one profile (SAST/DAST) is mandatory");
                                        }
                                    }}
                                />
                                <Form.Control.Feedback type="invalid">{props.errors?.newSASTProfile}</Form.Control.Feedback>
                            </InputGroup>
                        ) : (
                            <InputGroup className="mt-1">
                                <Form.Check
                                    required
                                    inline
                                    id="existingSASTProfile"
                                    type="radio"
                                    name="newProfile"
                                    label="Yes (Existing)"
                                    checked={isSASTProfile && !newSASTProfile}
                                    onChange={(e) => {
                                        setIsSASTProfile(true);
                                        setNewSASTProfile(false);
                                        props.setProfileDetails('newSASTProfile', false);
                                        props.setProfileDetails("isServiceAccountAssociated", false);
                                    }}
                                />
                                <Form.Check
                                    required
                                    inline
                                    id="newSASTProfile"
                                    type="radio"
                                    name="newProfile"
                                    label="Yes (New)"
                                    checked={isSASTProfile && newSASTProfile}
                                    onChange={(e) => {
                                        setIsSASTProfile(true);
                                        setNewSASTProfile(true);
                                        props.setProfileDetails('newSASTProfile', true);
                                        props.setProfileDetails("isServiceAccountAssociated", true);
                                    }}
                                />
                                <Form.Check
                                    required
                                    inline
                                    id="noSASTProfile"
                                    type="radio"
                                    name="newProfile"
                                    label="No"
                                    checked={!isSASTProfile}
                                    onChange={(e) => {
                                        e?.target?.parentElement?.classList?.remove("is-invalid");
                                        if(isDASTProfile) {
                                            setIsSASTProfile(false);
                                            props.setProfileDetails('newSASTProfile', false);
                                            props.setProfileDetails("isServiceAccountAssociated", true);
                                        } else {
                                            e?.target?.parentElement?.classList?.add("is-invalid");
                                            props.setFieldError("newSASTProfile", "Atlease one profile (SAST/DAST) is mandatory");
                                        }
                                    }}
                                />
                                <Form.Control.Feedback type="invalid">{props.errors?.newSASTProfile}</Form.Control.Feedback>
                            </InputGroup>
                        )}
                    </Form.Group>
                )}

                {isSASTProfile ? (
                    <Form.Group as={Col} md="6" className="mb-3">
                        <Form.Label>
                            SAST Application Version<span className="text-danger">*</span>
                            <Parcel config={HelpText} mountParcel={mountRootParcel} wrapWith="span" text="Version that the Fortify profile was created" />
                        </Form.Label>
                        {newSASTProfile ? (
                            <Parcel
                                config={TextField}
                                mountParcel={mountRootParcel}
                                id="app_version"
                                disabled
                                readonly
                                type="text"
                                eleName="app_version"
                                error={props.errors?.app_version}
                                value={`${dsoApplication.projectInfoDetails.projectName}-${dsoApplication.projectInfoDetails.assetId}-${repoName}-1-SAST`}
                                onChange={(e) => {{
                                    props.setProfileDetails(e.target.name, e.target.value)
                                }}}
                            />
                        ) : (
                            <Parcel
                                config={TextField}
                                mountParcel={mountRootParcel}
                                id="app_version"
                                required
                                maxLength={250}
                                type="text"
                                eleName="app_version"
                                data-display="Application Version"
                                defaultValue={props.profileDetails?.app_version ?? ''}
                                onChange={(e) => {{
                                    props.setProfileDetails(e.target.name, e.target.value)
                                }}}
                                onError={props.setFieldError}
                                error={props.errors?.app_version}
                            />
                        )}
                    </Form.Group>
                ) : !props.isCopyProfile && (<Form.Group as={Col} md="6" className="mb-3"></Form.Group>)}
                {!props.isCopyProfile && (
                    <Form.Group as={Col} md="6" className="mb-3">
                        {props.isEditable ? (
                            <>
                                <Form.Label>
                                    Do you want to associate a DAST profile?<span className="text-danger">*</span>
                                </Form.Label>
                                <InputGroup className="mt-1">
                                    <Form.Check
                                        required
                                        inline
                                        id="existingDASTProfile"
                                        type="radio"
                                        name="newDASTProfile"
                                        label="Yes"
                                        checked={isDASTProfile && !newDASTProfile}
                                        onChange={(e) => {
                                            setIsDASTProfile(true);
                                            setNewDASTProfile(false);
                                            props.setProfileDetails('isDASTProfile', true);
                                            props.setProfileDetails("isServiceAccountAssociated", false);
                                        }}
                                    />
                                    <Form.Check
                                        required
                                        inline
                                        id="noDASTProfile"
                                        type="radio"
                                        name="newDASTProfile"
                                        label="No"
                                        checked={!isDASTProfile}
                                        onChange={(e) => {
                                            e?.target?.parentElement?.classList?.remove("is-invalid");
                                            if(isSASTProfile) {
                                                setIsDASTProfile(false);
                                                props.setProfileDetails('isDASTProfile', false);
                                                props.setProfileDetails("isServiceAccountAssociated", true);
                                            } else {
                                                e?.target?.parentElement?.classList?.add("is-invalid");
                                                props.setFieldError("newDASTProfile", "Atlease one profile (SAST/DAST) is mandatory");
                                            }
                                        }}
                                    />
                                    <Form.Control.Feedback type="invalid">{props.errors?.newDASTProfile}</Form.Control.Feedback>
                                </InputGroup>
                            </>
                        ) : (
                            <>
                                <Form.Label>
                                    Do you want to associate a DAST profile?<span className="text-danger">*</span>
                                </Form.Label>
                                <InputGroup className="mt-1">
                                    <Form.Check
                                        required
                                        inline
                                        id="existingDASTProfile"
                                        type="radio"
                                        name="newDASTProfile"
                                        label="Yes (Existing)"
                                        checked={isDASTProfile && !newDASTProfile}
                                        onChange={(e) => {
                                            setIsDASTProfile(true);
                                            setNewDASTProfile(false);
                                            props.setProfileDetails('isDASTProfile', true);
                                            props.setProfileDetails("isServiceAccountAssociated", false);
                                        }}
                                    />
                                    <Form.Check
                                        required
                                        inline
                                        id="newDASTProfile"
                                        type="radio"
                                        name="newDASTProfile"
                                        label="Yes (New)"
                                        checked={isDASTProfile && newDASTProfile}
                                        onChange={(e) => {
                                            setIsDASTProfile(true);
                                            setNewDASTProfile(true);
                                            props.setProfileDetails('isDASTProfile', true);
                                            props.setProfileDetails("isServiceAccountAssociated", true);
                                        }}
                                    />
                                    <Form.Check
                                        required
                                        inline
                                        id="noDASTProfile"
                                        type="radio"
                                        name="newDASTProfile"
                                        label="No"
                                        checked={!isDASTProfile}
                                        onChange={(e) => {
                                            e?.target?.parentElement?.classList?.remove("is-invalid");
                                            if(isSASTProfile) {
                                                setIsDASTProfile(false);
                                                props.setProfileDetails('isDASTProfile', false);
                                                props.setProfileDetails("isServiceAccountAssociated", true);
                                            } else {
                                                e?.target?.parentElement?.classList?.add("is-invalid");
                                                props.setFieldError("newDASTProfile", "Atlease one profile (SAST/DAST) is mandatory");
                                            }
                                        }}
                                    />
                                    <Form.Control.Feedback type="invalid">{props.errors?.newDASTProfile}</Form.Control.Feedback>
                                </InputGroup>
                            </>
                        )}
                        
                    </Form.Group>
                )}
                {(isDASTProfile) ? (
                    <>
                        <Form.Group as={Col} md="6" className="mb-3">
                            <Form.Label>DAST Application Version<span className="text-danger">*</span>
                                <Parcel config={HelpText} mountParcel={mountRootParcel} wrapWith="span" text="DAST Version that the Fortify profile was created" />
                            </Form.Label>
                            {newDASTProfile ? (
                                <Parcel
                                    config={TextField}
                                    mountParcel={mountRootParcel}
                                    id="app_version_DAST"
                                    disabled
                                    readonly
                                    type="text"
                                    eleName="app_version_DAST"
                                    error={props.errors?.app_version_DAST}
                                    value={`${dsoApplication.projectInfoDetails.projectName}-${dsoApplication.projectInfoDetails.assetId}-${repoName}-1-DAST`}
                                    onChange={(e) => {{
                                        props.setProfileDetails(e.target.name, e.target.value)
                                    }}}
                                />
                            ) : (
                                <Parcel
                                    config={TextField}
                                    mountParcel={mountRootParcel}
                                    id="app_version_DAST"
                                    required
                                    maxLength={250}
                                    type="text"
                                    eleName="app_version_DAST"
                                    data-display="DAST Application Version"
                                    defaultValue={props.profileDetails?.app_version_DAST ?? ''}
                                    onChange={(e) => {{
                                        props.setProfileDetails(e.target.name, e.target.value)
                                    }}}
                                    onError={props.setFieldError}
                                    error={props.errors?.app_version_DAST}
                                />
                            )}
                        </Form.Group>
                        <Form.Group as={Col} md="6" className="mb-3">
                            <Form.Label>DAST CI Identifier
                                <Parcel config={HelpText} mountParcel={mountRootParcel} wrapWith="span" 
                                text={dastCIIdentifierHelpText} />
                            </Form.Label>
                            <Parcel
                                config={TextField}
                                mountParcel={mountRootParcel}
                                id="DASTIdentifier"
                                maxLength={50}
                                type="text"
                                eleName="dastciIdentifier"
                                data-display="DAST Identifier"
                                defaultValue={props.profileDetails?.dastciIdentifier ?? ''}
                                onChange={(e) => {{
                                    props.setProfileDetails(e.target.name, e.target.value);
                                }}}
                                onError={props.setFieldError}
                                error={props.errors?.dastciIdentifier}
                            />
                        </Form.Group>
                    </>
                ) : !props.isCopyProfile && (<Form.Group as={Col} md="6" className="mb-3"></Form.Group>)}
                {(((isSASTProfile && newSASTProfile) || (isDASTProfile && newDASTProfile)) && !props.isCopyProfile && !props.isEditable) && (
                    <>
                        <Form.Group as={Col} md="6" className="mb-3">
                            <Form.Label>
                                Application Owner<span className="text-danger">*</span>
                            </Form.Label>
                            <UserSelect
                                required
                                placeholder="Search User"
                                onChange={(selected) => {
                                    setFortifyAttribute(31, selected.displayName)
                                    setFortifyAttribute(32, selected.value)
                                }}
                                // defaultValue={application?.projectInfoDetails?.technicalAdmin}
                                elementName="technicalAdmin" />
                        </Form.Group>
                        <Form.Group as={Col} md="6" className="mb-3">
                            <Form.Label>
                                Application Type<span className="text-danger">*</span>
                            </Form.Label>
                            <Parcel
                                eleName="applicationType"
                                required
                                defaultValue={getAttributeValue(8)}
                                errorMessage="Please select Application Type.!"
                                mountParcel={mountRootParcel}
                                config={Select}
                                getOptionValue={(option) => option.guid}
                                getOptionLabel={(option) => option.name}
                                onChange={(selected) => { setFortifyAttribute(8, [selected]) }}
                                options={getAttributeOptions(8)}
                            ></Parcel>
                        </Form.Group>
                        <Form.Group as={Col} md="6" className="mb-3">
                            <Form.Label>
                                Region<span className="text-danger">*</span>
                            </Form.Label>
                            <Parcel
                                eleName="region"
                                required
                                defaultValue={getAttributeValue(16)}
                                errorMessage="Please select Region.!"
                                mountParcel={mountRootParcel}
                                config={Select}
                                getOptionValue={(option) => option.guid}
                                getOptionLabel={(option) => option.description}
                                onChange={(selected) => { setFortifyAttribute(16, [selected]) }}
                                options={getAttributeOptions(16)}
                            ></Parcel>
                        </Form.Group>
                        <Form.Group as={Col} md="6" className="mb-3">
                            <Form.Label>
                                Risk Level<span className="text-danger">*</span>
                                <Parcel config={HelpText} mountParcel={mountRootParcel} wrapWith="span" text={riskHelpLink} />
                            </Form.Label>
                            <Parcel
                                eleName="riskLevel"
                                required
                                defaultValue={getAttributeValue(1)}
                                errorMessage="Please select Risk Level.!"
                                mountParcel={mountRootParcel}
                                config={Select}
                                getOptionValue={(option) => option.guid}
                                getOptionLabel={(option) => option.name}
                                onChange={(selected) => { setFortifyAttribute(1, [selected]) }}
                                options={getAttributeOptions(1)}
                            ></Parcel>
                        </Form.Group>
                    </>
                )}
                {isSASTProfile && !newSASTProfile && (
                    <Form.Group as={Col} md="6" className="mb-3">
                        <Form.Label>
                            Is Service Account Associated?<span className="text-danger">*</span>
                        </Form.Label>
                        <InputGroup className="mt-1">
                            <Form.Check
                                required
                                inline
                                id="serviceAccount1"
                                type="radio"
                                name="isServiceAccountAssociated"
                                label="Yes"
                                checked={props.profileDetails?.isServiceAccountAssociated}
                                onChange={(e) => props.setProfileDetails(e.target.name, true)}
                            />
                            <Form.Check
                                required
                                inline
                                id="serviceAccoun2"
                                type="radio"
                                name="isServiceAccountAssociated"
                                label="No"
                                checked={!props.profileDetails?.isServiceAccountAssociated}
                                onChange={(e) => props.setProfileDetails(e.target.name, false)}
                            />
                        </InputGroup>
                    </Form.Group>
                )}
            </Row>
            {(isSASTProfile && !newSASTProfile && !props.profileDetails?.isServiceAccountAssociated) && (
                <Row className="mb-3">
                    <Form.Group as={Col} md="12">
                        <div className="alert alert-warning alert-dismissible fade show" role="alert">
                            If you have not created your Fortify profile through DSOE Portal, please make sure it is associated with DSOE Service accounts Uscmpfortinpdsvc, Uscmpfortiprdsvc, otherwise scans will not run.
                            <br /><br />
                            Associate your Profile to DSOE Service accounts using this <a href="https://deloitteglobal.service-now.com/sp?id=sc_cat_item&sys_id=edf4ee9937c90b0031119c9953990e88" target="_blank">LINK</a>
                        </div>
                    </Form.Group>
                </Row>
            )}
        </>
    )
}

export default Fortify;