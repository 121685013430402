import { BaseService } from "./base.service";

const businessAreas = {
  Advisory: [
    { value: '9', label: 'Accounting & Internal Controls' },
    { value: '8', label: 'Advisory National' },
    { value: '10', label: 'Cyber & Strategic Risk' },
    { value: '11', label: 'GPS Operational Excellence' },
    { value: '12', label: 'Regulatory & Legal Support' },
    { value: '13', label: 'Transactions and M&A' }
  ],
  Audit: [],
  Consulting: [
    { value: '55', label: 'Core Business Operations' },
    { value: '56', label: 'Customer & Marketing' },
    { value: '57', label: 'Enterprise Performance' },
    { value: '61', label: 'Human Capital' },
    { value: '58', label: 'Mergers & Acquisitions' },
    { value: '59', label: 'National Office' },
    { value: '60', label: 'Strategy & Analytics' }
  ],
  ITS: [
    { value: '15', label: 'Cyber Security' },
    { value: '14', label: 'Deloitte Application Studios' },
    { value: '16', label: 'Technology & Infrastructure' },
    { value: '17', label: 'Technology Management Office' },
    { value: '18', label: 'Technology Support Services' }
  ],
  Services: [],
  Tax: [
    { value: '25', label: 'Business Tax Services (BTS)' },
    { value: '26', label: 'Global Employer Services (GES)' },
    { value: '27', label: 'International Tax / Transfer Pricing (IT / TP)' },
    { value: '28', label: 'Legal Business Services (LBS)' },
    { value: '29', label: 'Mergers and Acquisitions (M&A)' },
    { value: '30', label: 'Multistate Tax Services (MTS)' },
    { value: '31', label: 'National Federal Tax Services (NFTS)' },
    { value: '32', label: 'Partnership Solutions Group (PSG)' },
    { value: '33', label: 'Tax Automation Center of Excellence (ACE)' },
    { value: '34', label: 'Tax Management Consulting (TMC)' },
    { value: '35', label: 'Tax National' },
    { value: '36', label: 'Tax Transformation Office (TTO)' }
  ]
};

class AppRegService extends BaseService {
  constructor() {
    super();
    this.baseUrl = process.env.APPREG_API_URL;
  }

  public async getProjectDetails(dsoAppId: string) {
    let url = `${this.baseUrl}/api/v3/project/${dsoAppId}`;
    try {
      let response = await this.get(url);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  public async createProject(appRegBody: any) {
    let url = `${this.baseUrl}/api/v3/project`;
    try {
      let response = await this.post(url, appRegBody);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  public async updateProject(appRegBody: any) {
    let url = `${this.baseUrl}/api/v3/project`;
    try {
      let response = await this.put(url, appRegBody);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  public async isValidADORepo(params: any): Promise<any> {
      try {
          let response = await this.get(`${this.baseUrl}/api/v3/ValidateAdoRepository`, params);
          return response.success;
      } catch (error) {
          console.error(error);
          return false;
      }
  }

  public async getMemberFirms() {
    let data = [
      { value: 'AFRCA', label: 'Africa' },
      { value: 'AU', label: 'Australia' },
      { value: 'AT', label: 'Austria' },
      { value: 'BR', label: 'Brazil' },
      { value: 'CA', label: 'Canada' },
      { value: 'CBC', label: 'Caribbean Cluster' },
      { value: 'CENEU', label: 'Central Europe' },
      { value: 'CN', label: 'China' },
      { value: 'CIS', label: 'CIS' },
      { value: 'CY', label: 'Cyprus' },
      { value: 'CENMD', label: 'DCM - Greece' },
      { value: 'IT', label: 'DCM - Italy' },
      { value: 'CENMD', label: 'DCM - Malta' },
      { value: 'FI', label: 'Finland' },
      { value: 'FR', label: 'France' },
      { value: 'DE', label: 'Germany' },
      { value: 'GI', label: 'Gibraltar' },
      { value: 'IS', label: 'Iceland' },
      { value: 'IN', label: 'India' },
      { value: 'IE', label: 'Ireland' },
      { value: 'IL', label: 'Israel' },
      { value: 'JP', label: 'Japan' },
      { value: 'KR', label: 'Korea' },
      { value: 'LATCO', label: 'LATCO' },
      { value: 'LU', label: 'Luxembourg' },
      { value: 'MU', label: 'Mauritius' },
      { value: 'MX', label: 'Mexico' },
      { value: 'MDEST', label: 'Middle East' },
      { value: 'NL', label: 'Netherlands' },
      { value: 'NZ', label: 'New Zealand' },
      { value: 'GB', label: 'Northwest Europe' },
      { value: 'NO', label: 'Norway' },
      { value: 'PK', label: 'Pakistan' },
      { value: 'PT', label: 'Portugal' },
      { value: 'SEA', label: 'Southeast Asia Cluster' },
      { value: 'ES', label: 'Spain' },
      { value: 'SE', label: 'Sweden' },
      { value: 'CH', label: 'Switzerland' },
      { value: 'TW', label: 'Taiwan' },
      { value: 'TR', label: 'Turkey' },
      { value: 'US', label: 'United States' },
      { value: 'DT', label: 'Deloitte Technology' },
      { value: 'Global', label: 'DTTL' }
    ]

    try {
      // let response = await this.get(url);
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  public async getBusinessAreas(func: string) {
    

    try {
      // let response = await this.get(url);
      return businessAreas[func];
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  public async getFunctionGroups() {
    let data = [
      { value: 'Audit', label: 'Audit' },
      { value: 'Advisory', label: 'Advisory' },
      { value: 'Consulting', label: 'Consulting' },
      { value: 'Enabling Areas', label: 'Enabling Areas'},
      { value: 'Federal', label: 'Federal' },
      { value: 'Tax', label: 'Tax' }
    ]

    try {
      // let response = await this.get(url);
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  public getGitOrganizations() {
    try {
      const orgs = process.env.GITHUB_ORGANIZATION_LIST;
      const organizations = orgs.split(",").map(org => {
        const data = org.split(":");
        return {label: data[1], value: data[0]}
      })
      return organizations;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}

const AppRegistrationService = new AppRegService();

export default AppRegistrationService;
