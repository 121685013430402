import Tippy from "@tippyjs/react";
import 'tippy.js/dist/tippy.css';
import styled from "styled-components";

const CustomTippy = styled(Tippy)`
    color: #000;
    background: #fff;

    /* Styling the arrow for different placements */
    &[data-placement^='top'] > .tippy-arrow::before {
        border-top-color: #fff;
        border-top: 1px solid #0000002d;
    }
    border: 1px solid #0000002d;
`;

export const Tooltip = (props: any) => {

    const { content, title } = props;

    return (
        <>
            <CustomTippy content={content}>
                <div style={{ display: "inline-flex" }}><div>{title}</div><img src="/assets/images/info.svg" height="16" /></div>
            </CustomTippy>
        </>
    );
}
