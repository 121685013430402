import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Parcel from "single-spa-react/parcel";
import { mountRootParcel } from 'single-spa';
import { components, util } from '@deloitte-global-cloud-services//dsoe-portal-core';
import { MyApplications } from "./breadcrumb-links";
import { useEffect, useState } from "react";
import BackendAPIService from "../../services/backendapi.service";
import AppRegistrationService from "../../services/appreg.service";
import { useDSOContext } from "../../context/DataLayer";

import RegisterApp from "./RegisterApp";
import { SET_DSOE_CONTEXT } from "../../context/reducer";

const { Breadcrumb, Loader } = components;
const { DateUtil } = util;


const isAlphaNumberic = (str) => /^[a-z0-9]+$/i.test(str);

export const SanitizedLink = ({ prefix, id, suffix, children, ...props }) => {

    const santiziedId = id && isAlphaNumberic(id) ? id : "error"

    const trimedSuffix = suffix?.trim();
    const to = `${prefix}/${santiziedId}${trimedSuffix}`;

    return <Link to={to} {...props}> {children} </Link>
}

const ApplicationHome = () => {

    const { dsoAppId } = useParams();
    const [data, setData] = useState<any>({});
    const [newApplication, setNewApplication] = useState(false);
    const [appRegModalShow, setAppRegModalShow] = useState(false);
    const [milestones, setMilestones] = useState([]);
    const [loading, setLoading] = useState(true);
    const [reloadPage, setReloadPage] = useState(false);

    const [_dsoApplication, dispatch] = useDSOContext();

    useEffect(() => {
        setLoading(true);
        AppRegistrationService.getProjectDetails(dsoAppId).then(data => {
            setData(data);
            setLoading(false);
            dispatch({
                type: SET_DSOE_CONTEXT,
                dsoApplication: data
            });
        });
        BackendAPIService.getAllMilestone(dsoAppId).then((milestones: any) => {
            setMilestones(milestones);
        });
    }, [reloadPage]);

    const colWidth = milestones && 100 / milestones.length;
    const completedMileStonesCount = milestones && milestones.filter(milestone => milestone.isComplete).length;
    const progressWidth = milestones && 100 * (completedMileStonesCount / milestones.length)

    return (
        <>
            {appRegModalShow && <RegisterApp show={appRegModalShow} onUpdate={() => { setReloadPage(!reloadPage) }} appData={newApplication ? {} : data} onCloseModal={() => setAppRegModalShow(false)} />}
            {loading ? (
                <Parcel config={Loader} mountParcel={mountRootParcel} />
            ) : (
                <>
                    <div className="row pt-3 pb-3">
                        <Parcel
                            mountParcel={mountRootParcel}
                            config={Breadcrumb}
                            wrapWith="div"
                            wrapClassName="col"
                            links={[...MyApplications, { name: data.projectInfoDetails.projectName }]}
                        ></Parcel>
                        <div className="col-12 col-sm-6 col-lg-4 text-end">
                            <a className="btn btn-primary float-right" href={undefined} onClick={() => {
                                setNewApplication(true);
                                setAppRegModalShow(true)
                            }}>Register a New Application</a>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-4">
                            <div className="card rounded shadow-sm mb-4 text-center">

                                <div className="card-body">
                                    <h5 className="card-title pt-3 pb-3">
                                        APPLICATION DETAILS </h5>
                                    <div className="card-text text-secondary">
                                        <div className="pt-2 pb-2">
                                            <span className="fw-bold">DSO Application Name : </span>
                                            <span className="pl-2">{data.projectInfoDetails?.projectName}</span>
                                        </div>
                                        <div className="pt-2 pb-2">
                                            <span className="fw-bold">DSO Application ID : </span>
                                            <span className="pl-2">{data.projectInfoDetails?.dsoAppId}</span>
                                        </div>
                                        <div className="pt-2 pb-2">
                                            <span className="fw-bold">Budget Manager : </span>
                                            <span className="pl-2">{data.projectInfoDetails?.budgetManager}</span>
                                        </div>
                                        <div className="pt-2 pb-2">
                                            <span className="fw-bold">Charge Code : </span>
                                            <span className="pl-2">{data.projectInfoDetails?.chargeCode}</span>
                                        </div>
                                        <div className="pt-2 pb-2">
                                            <span className="fw-bold">Technical Admin : </span>
                                            <span className="pl-2">{data.projectInfoDetails?.technicalAdmin}</span>
                                        </div>
                                        <div className="pt-2 pb-2">
                                            <span className="fw-bold">Primary Contact : </span>
                                            <span className="pl-2">{data.projectInfoDetails?.primaryContact}</span>
                                        </div>
                                        <div className="pt-2 pb-2">
                                            <span className="fw-bold">APM ID : </span>
                                            <span className="pl-2">{data.projectInfoDetails?.assetId}</span>
                                        </div>
                                        <div className="pt-2 pb-5">
                                            <span className="fw-bold">Description : </span>
                                            <span className="pl-2">{data.projectInfoDetails?.projectDescription}</span>
                                        </div>
                                        <div className="pt-2">
                                            <div className="mt-5 text-end">
                                                <a className="btn btn-sm btn-primary" href={undefined} onClick={() => {
                                                    setNewApplication(false);
                                                    setAppRegModalShow(true)
                                                }}>
                                                    Edit
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-8">
                            <div className="card rounded shadow-sm mb-4">
                                <div className="card-body">
                                    <h5 className="card-title">Milestones</h5>
                                    <div className="card-text text-secondary">
                                        <div className="m-4 mb-0">
                                            <div className="row">
                                                {milestones && milestones.map((milestone, index) =>
                                                (
                                                    <div key={index} className="col-6 col-md-3" style={{ width: `${colWidth}%` }}>{milestone.milestoneType}</div>
                                                )
                                                )}
                                            </div>
                                            <div className="row">
                                                <div className="col pt-2 pb-2">
                                                    <div className="progress" style={{ "height": "10px" }}>
                                                        <div className="progress-bar bg-success" role="progressbar" style={{ width: `${progressWidth}%` }} aria-valuenow={75} aria-valuemin={0} aria-valuemax={100}></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                {milestones && milestones.map((milestone, index) =>
                                                (
                                                    <div key={index} className="col-6 col-md-3" style={{ width: `${colWidth}%` }}>{DateUtil.toDisplayDate(milestone.milestoneDate)}</div>
                                                )
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer bg-transparent border-top-0 pb-3 text-end">

                                    <SanitizedLink prefix={"/viewapp"} id={data.projectInfoDetails?.dsoAppId} suffix={"/milestones"} className="btn btn-sm btn-primary" >
                                        Edit
                                    </SanitizedLink>
                                </div>
                            </div>

                            <div className="card rounded shadow-sm mb-4 text-center">
                                <div className="card-body pb-0">
                                    <div className="row">
                                        <div className="col-12 col-md-6">
                                            <div className="card rounded shadow-sm mb-4">
                                                <div className="card-body text-center">
                                                    <SanitizedLink prefix={"/scanresults"} id={data.projectInfoDetails?.dsoAppId} suffix={""} className="text-decoration-none" >
                                                        <h5 className="card-title"><strong>Scan Results</strong></h5>
                                                    </SanitizedLink>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6" hidden>
                                            <div className="card rounded shadow-sm mb-4">
                                                <div className="card-body text-center">
                                                    <SanitizedLink prefix={"/compliancerecords"} id={data.projectInfoDetails?.dsoAppId} suffix={""} className="text-decoration-none" >
                                                        <h5 className="card-title">Compliance Records</h5>
                                                        <div className="card-text text-secondary">
                                                            Compliance Records
                                                        </div>
                                                    </SanitizedLink>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="card rounded shadow-sm mb-4">
                                                <div className="card-body text-center">
                                                    <SanitizedLink prefix={"/managerepo"} id={data.projectInfoDetails?.dsoAppId} suffix={""} className="text-decoration-none" >
                                                        <h5 className="card-title"><strong>Manage Repositories</strong></h5>
                                                    </SanitizedLink>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="card rounded shadow-sm mb-4">
                                                <div className="card-body text-center">
                                                    <SanitizedLink prefix={"/releasehistory"} id={data.projectInfoDetails?.dsoAppId} suffix={""} className="text-decoration-none" >
                                                        <h5 className="card-title"><strong>Release History</strong></h5>
                                                    </SanitizedLink>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="card rounded shadow-sm mb-4">
                                                <div className="card-body text-center">
                                                    <SanitizedLink prefix={"/assets"} id={data.projectInfoDetails?.dsoAppId} suffix={""} className="text-decoration-none" >
                                                        <h5 className="card-title">Assets</h5>
                                                    </SanitizedLink>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="card rounded shadow-sm mb-4">
                                                <div className="card-body text-center">
                                                    <SanitizedLink prefix={"/buildhistory"} id={data.projectInfoDetails?.dsoAppId} suffix={""} className="text-decoration-none" >
                                                        <h5 className="card-title"><strong>Build History</strong></h5>
                                                    </SanitizedLink>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="card rounded shadow-sm mb-4">
                                                <div className="card-body text-center">
                                                    <SanitizedLink prefix={"/ssdlccompliance"} id={data.projectInfoDetails?.dsoAppId} suffix={""} className="text-decoration-none" >
                                                        <h5 className="card-title">SSDLC Compliance</h5>
                                                    </SanitizedLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

export default ApplicationHome;