import { BaseService, ServiceResponse } from "./base.service";

interface User {
  businessPhones: string[] | null,
  displayName: string | null,
  givenName: string | null,
  jobTitle: string | null,
  mail: string | null,
  mobilePhone: string | null,
  officeLocation: string | null,
  preferredLanguage: string | null,
  surname: string | null,
  userPrincipalName: string | null,
  id: string | null
}

interface GraphResponse {
  value: User[]
}

class MSGraphService extends BaseService {
  constructor() {
    super();
    this.baseUrl = "https://graph.microsoft.com/v1.0";
  }

  protected async getAccessToken(): Promise<string> {
    const authModule = await System.import('@deloitte-dsoe/portal-auth');
    return authModule.context.value.graphAPIToken;
  }

  public async listUsers(userName: string) {
    let url = `${this.baseUrl}/users?$search="userPrincipalName:${userName}" OR "displayName:${userName}"`;
    try {
      let response: ServiceResponse<GraphResponse> = await this.get(url, undefined, undefined, undefined, {
        headers: {
          ConsistencyLevel: 'eventual'
        }
      });

      const users: GraphResponse = response.data;
      const filteredUsers = users?.value?.filter((user: { mail: string | null }) => user.mail && user.mail.includes('@deloitte.'));
      
      return filteredUsers;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  public async getUser(email: string) {
    let url = `${this.baseUrl}/users/${email}`;
    try {
      let response = await this.get(url);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}

const UserService = new MSGraphService();

export default UserService;