import { BaseService } from "./base.service";

class GenesisApiService extends BaseService {
    constructor() {
        super();
        this.baseUrl = process.env.GENESIS_GITHUB_API_URL;
    }

    protected async getAuthoHeader(): Promise<any> {
        const key = process.env.GENESIS_ARTIFACT_SUBSCRIPTIONKEY;
        return {
          'Ocp-Apim-Subscription-Key': key
        }
    }

    public async validaGithHubUser(gitHubUser: string, organization: string): Promise<any> {
        let url = `${this.baseUrl}/ValidateUserEmail`;
        let response = await this.validate(url, {
            email: gitHubUser,
            organization
        });
        if(response !== undefined) {
            return {
                developerEmailId: gitHubUser,
                devGithubUserId: response
            }
        }

        url = `${this.baseUrl}/ValidateUserId`;
        response = await this.validate(url, {
            userId: gitHubUser,
            organization
        });
        if(response !== undefined) {
            return {
                developerEmailId: response,
                devGithubUserId: gitHubUser
            }
        }
        return false;
    }

    private async validate(url: string, body: any) {
        try {
            let response = await this.post(url, body);
            return response.data;
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    public async isValidGithHubRepo(repoName: string, organization: string): Promise<any> {
        try {
            let response = await this.post(`${this.baseUrl}/ValidateGitHubRepo`, {
                repoName,
                organization
            });
            return response.success;
        } catch (error) {
            console.error(error);
            return false;
        }
    }
}

const GenesisService = new GenesisApiService();

export default GenesisService;