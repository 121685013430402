import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BackendAPIService from "../../services/backendapi.service";
import RegisterApp from "./RegisterApp";
import { SanitizedLink } from "./ApplicationHome";

const ListApplication = () => {
    const [applications, setApplications] = useState([]);
    const [appRegModalShow, setAppRegModalShow] = useState(false);

    useEffect(() => {
        const loadApplications = async () => {
            const authModule = await System.import('@deloitte-dsoe/portal-auth');
            const applications = await BackendAPIService.getAllApps(authModule.context.value?.user?.email);
            setApplications(applications);
        };
        loadApplications();
    }, []);

    return (
        <>
            {appRegModalShow ?
                <RegisterApp show={appRegModalShow} appData={{}} onCloseModal={() => setAppRegModalShow(false)} /> : ''}
            <div className="row pt-3 pb-3">
                <div className="col-12 col-sm-6 col-lg-8">
                    <h4 className="text-secondary">My Applications</h4>
                </div>
                <div className="col-12 col-sm-6 col-lg-4 text-end">
                    <a className="btn btn-primary float-right" href="#" onClick={() => { setAppRegModalShow(true) }}>Register a New Application</a>
                </div>
            </div>
            <div className="row">
                {applications?.map((application, index) => {
                    return (
                        <div key={index} className="col-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
                            <div className="card rounded shadow-sm mb-4 text-center">
                                <div className="card-body">
                                    <SanitizedLink prefix={"/viewapp"} id={application.dsoAppId} suffix={""}  className="text-decoration-none">
                                        <h5 className="card-title pt-3 pb-2">{application.projectName}</h5>
                                    </SanitizedLink>
                                
                                    <div className="card-text text-secondary">
                                        <div className="pt-1 pb-1">
                                            <span className="fw-bold">DSO App ID : </span>
                                            <span className="pl-2">{application.dsoAppId}</span>
                                        </div>
                                        <div className="pt-1 pb-1">
                                            <span className="fw-bold">Primary Contact : </span>
                                            <span className="pl-2">{application.primaryContact}</span>
                                        </div>
                                        <div className="pt-1 pb-1">
                                            <span className="fw-bold">Technical Admin : </span>
                                            <span className="pl-2">{application.technicalAdmin}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </>
    );
};

export default ListApplication;
