import axios from "axios";
import { BaseService } from "./base.service";

class BackendService extends BaseService {

  protected uploadURL: string = process.env.GENESIS_ARTIFACT_URL;

  constructor() {
    super();
    this.baseUrl = process.env.BACKEND_API_URL;
  }

  public async getAllApps(userId: string): Promise<any> {
    let url = `${this.baseUrl}/api/v1/project/applications/${userId}`;
    try {
      let response = await this.get(url);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  public async getAllBuildHistory(dsoAppId: string, startIndex?: number, pageSize?: number) {
    let url = `${this.baseUrl}/api/v1/project/builds/${dsoAppId}`;
    try {
      let response = await this.get(url, {
        startIndex,
        pageSize,
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  public async getAllReleaseHistory(dsoAppId: string, startIndex?: number, pageSize?: number) {
    let url = `${this.baseUrl}/api/v1/project/release/${dsoAppId}`;
    try {
      let response = await this.get(url, {
        startIndex,
        pageSize,
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  public async getAllCompliance(dsoAppId: string, startIndex?: number, pageSize?: number) {
    let url = `${this.baseUrl}/api/v1/project/compliance/${dsoAppId}`;
    try {
      let response = await this.get(url, {
        startIndex,
        pageSize,
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  public async getAllScanResults(dsoAppId: string, startIndex?: number, pageSize?: number) {
    let url = `${this.baseUrl}/api/v1/project/scans/${dsoAppId}`;
    try {
      let response = await this.get(url, {
        startIndex,
        pageSize,
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  public async getAssetTypes() {
    let url = `${this.baseUrl}/api/v1/assets/supported`;
    try {
      let response = await this.get(url);
      return response.data;
    } catch (error) {
      console.error(error);
      // throw error;
    }
  }

  public async createAsset(asset: any) {
    let url = `${this.baseUrl}/api/v1/assets`;
    try {
      let response = await this.post(url, [asset]);
      return response;
    } catch (error) {
      console.error(error);
      // throw error;
    }
  }

  public async updateAsset(asset: any) {
    let url = `${this.baseUrl}/api/v1/assets`;
    try {
      let response = await this.put(url, [asset]);
      return response;
    } catch (error) {
      console.error(error);
      // throw error;
    }
  }

  public async getAllAssets(dsoAppId: string) {
    let url = `${this.baseUrl}/api/v1/assets/${dsoAppId}`;
    try {
      let response = await this.get(url);
      return response.data;
    } catch (error) {
      console.error(error);
      // throw error;
    }
  }

  public async getSupportedMilestones() {
    let url = `${this.baseUrl}/api/v1/milestone/supported`;
    try {
      let response = await this.get(url);
      return response.data;
    } catch (error) {
      console.error(error);
      // throw error;
    }
  }

  public async createMilestone(milestone: any) {
    let url = `${this.baseUrl}/api/v1/milestone`;
    try {
      let response = await this.post(url, [milestone]);
      return response;
    } catch (error) {
      console.error(error);
      // throw error;
    }
  }

  public async getAllMilestone(dsoAppId: string) {
    let url = `${this.baseUrl}/api/v1/milestone/${dsoAppId}`;
    try {
      let response = await this.get(url);
      return response.data;
    } catch (error) {
      console.error(error);
      // throw error;
    }
  }

  public async updateMilestone(milestone: any) {
    let url = `${this.baseUrl}/api/v1/milestone`;
    try {
      let response = await this.put(url, [milestone]);
      return response;
    } catch (error) {
      console.error(error);
      // throw error;
    }
  }

  public async getAllRequirements(dsoAppId: string): Promise<any> {
    let url = `${this.baseUrl}/api/v1/ssdlc/requirements?dsoAppId=${dsoAppId}`;
    try {
      let response = await this.get(url);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  public async getAllStatements(dsoAppId: string, securityId: string): Promise<any> {
    let url = `${this.baseUrl}/api/v1/ssdlc/statements?dsoAppId=${dsoAppId}&securityId=${securityId}`;
    try {
      let response = await this.get(url);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  public async uploadDocument(data: any): Promise<any> {
    let formData = new FormData();
    formData.append("file", data.file);
    formData.append("buildVersion", data.buildVersion);
    formData.append("dsoAppId", data.dsoAppId);
    formData.append("repoName", data.repoName);
    formData.append("scanEngine", "codeql");
    let url = `${this.uploadURL}/v1/artifact`;
    try {
      const response = await axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Ocp-Apim-Subscription-Key": process.env.GENESIS_ARTIFACT_SUBSCRIPTIONKEY
        }
      });
      return { ...response, success: true };
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  public async updateStatement(statement: any): Promise<any> {
    let url = `${this.baseUrl}/api/v1/ssdlc/statements`;
    try {
      let response = await this.put(url, statement);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  public async updateRequirementStatus(dsoAppId: string, id: number, statusId: number): Promise<any> {
    let url = `${this.baseUrl}/api/v1/ssdlc/requirements?dsoAppId=${dsoAppId}&id=${id}&statusId=${statusId}`;
    try {
      let response = await this.put(url);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}

const BackendAPIService = new BackendService();

export default BackendAPIService;
