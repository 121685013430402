import { Modal, Form, Row, Col, Button } from "react-bootstrap";

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Parcel from "single-spa-react/parcel";
import { mountRootParcel } from 'single-spa';
import { components, util } from "@deloitte-global-cloud-services/dsoe-portal-core";
import moment from "moment";
import BackendAPIService from "../../services/backendapi.service";
import { MyApplications } from "./breadcrumb-links";
import { Form as BaseForm } from '../../components/Form'
import { alertMessage, showMessageOnPopup } from "../../components/MessageAlert";
import { useDSOContext } from "../../context/DataLayer";
import APIUtil from "../../utils/APIUtil";

const { Breadcrumb, Loader, DatePicker, Select, Button: AsyncButton, HelpText } = components;
const { DateUtil } = util;

const ManageMilestones = () => {
  const { dsoAppId } = useParams();
  const [data, setData] = useState(undefined);
  const [milestone, setMilestone] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(true);
  const [{dsoApplication}] = useDSOContext();

  useEffect(() => {
    BackendAPIService.getAllMilestone(dsoAppId).then((data: any) => {
      setData(data);
      setLoading(false);
    });
  }, [reload]);

  return (
    <>
      <div className="row pt-3 pb-3">
        <Parcel
          mountParcel={mountRootParcel}
          config={Breadcrumb}
          wrapWith="div"
          wrapClassName="col"
          links={[
            ...MyApplications,
            {
              name: dsoApplication?.projectInfoDetails?.projectName,
              url: `/dsoeportal/viewapp/${dsoAppId}`,
            },
            {
              name: "Milestones",
            },
          ]}
        ></Parcel>
        <div className="col-12 col-sm-6 col-lg-4 text-end">
          <a className="btn btn-primary float-right" type="button" onClick={() => setModalShow(true)}>
            Add a Milestone
          </a>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="bg-white pt-3 pb-3">
            <h5 className="page-title ps-3">Manage Milestones</h5>
            <div className="row">
              <div className="col">
                <div className="table-container">
                  <table className="table">
                    <thead>
                      <tr>
                        <th style={{ width: "40%" }}>Milestone</th>
                        <th style={{ width: "30%" }}>Date</th>
                        <th style={{ width: "30%" }} className="text-center">
                          Completed
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <tr><td colSpan={3}><Parcel config={Loader} mountParcel={mountRootParcel} /></td></tr>
                      ) : data && data.length ? data.map((milestone: any, index: number) => (
                        <tr key={index}>
                          <td>
                            <a className="cursor-pointer" href={undefined} onClick={() => {
                              setMilestone(milestone)
                              setModalShow(true)
                            }}>
                              {milestone.milestoneType}
                            </a>
                          </td>
                          <td>{DateUtil.toDisplayDate(milestone.milestoneDate)}</td>
                          <td className="text-center">{milestone.isComplete ? "Yes" : "No"}</td>
                        </tr>
                      )) : (
                        <tr>
                          <td colSpan={4}>No Milestone found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MilestoneModal
        onUpdate={() => {
          setReload(!reload);
          setModalShow(false);
        }}
        dsoAppId={dsoAppId}
        milestone={milestone}
        allMilestones={data}
        setMilestone={setMilestone}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

const MilestoneModal = (props) => {
  const { dsoAppId, onUpdate, milestone, setMilestone, allMilestones, ...restProps } = props;
  const [supportedMilestones, setSupportedMilestones] = useState(undefined);
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState<any>();

  const availableStatus = [
    { label: "InProgress", value: 'false' },
    { label: "Completed", value: 'true' },
  ];

  const setFieldError = (name, error) => {
      setErrors({ ...errors, [name]: error });
  }

  useEffect(() => {
    BackendAPIService.getSupportedMilestones().then((data) => setSupportedMilestones(data));
  }, []);

  const handleSelectChange = (property: string, value: string) => {
    setMilestone({ ...milestone, [property]: value })
  }

  const handleModalClose = () => {
    props.onHide();
    setMilestone({});
  };

  const validateMilestoneStatus = (): boolean => {
    let hasErrors = false;
    document.getElementById("isComplete")?.classList?.remove("is-invalid");
    if (milestone.milestoneDate && milestone.isComplete) {
      const isCompleted = milestone.isComplete === "true" || milestone.isComplete === true;
      const milestoneDate = moment(milestone.milestoneDate);
      if (isCompleted) {
        const prevMilestones = allMilestones.filter((ms: any) => {
          const date = new Date(ms.milestoneDate);
          date.setHours(0);
          return milestoneDate.diff(date, 'days') >= 0 && milestone.applicationId !== ms.applicationId
        });
        hasErrors = prevMilestones.some((ms: any) => ms.isComplete === false);
      } else {
        const featureMilestones = allMilestones.filter((ms: any) => {
          const date = new Date(ms.milestoneDate);
          date.setHours(0);
          return milestoneDate.diff(date, 'days') <= 0 && milestone.applicationId !== ms.applicationId
        });
        hasErrors = featureMilestones.some((ms: any) => ms.isComplete === true);
      }
    }
    if (hasErrors) document.getElementById("isComplete")?.classList?.add("is-invalid");
    return hasErrors;
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setLoader(true);
    if (validateMilestoneStatus()) {
      event.stopPropagation();
      setLoader(false);
      return;
    }
    let response: any;
    let message = 'Milestone was created successfully.';
    milestone.milestoneDate = milestone?.milestoneDate?.toLocaleString();
    if (milestone.applicationId === undefined) {
      milestone.dsoAppId = dsoAppId;
      response = await BackendAPIService.createMilestone(milestone);
    } else {
      message = 'Milestone was updated successfully.';
      response = await BackendAPIService.updateMilestone(milestone);
    }
    setLoader(false);
    if (response?.success) {
      alertMessage({ message });
      handleModalClose();
      onUpdate();
    } else {
      showMessageOnPopup({
        message: `Milestone ${milestone.applicationId === undefined ? 'creation' : 'update'} failed. ${APIUtil.getErrorMessageLink(response?.error?.details)}`
      });
    }

  };

  return (
    <Modal {...restProps} onHide={handleModalClose} backdrop="static" aria-labelledby="contained-modal-title-vcenter" className="modal-right">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="page-title">
          {milestone.applicationId === undefined ? "Add Milestone" : "Update Milestone"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="scrollbar">
        <BaseForm setLoader={setLoader} errors={errors} onError={setErrors} id='milestoneForm' onSubmit={handleSubmit}>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="milestoneTypeSelector">
              <Form.Label>
                <b>Milestone Type</b>
                <Parcel config={HelpText} mountParcel={mountRootParcel} wrapWith="span" text="The milestone in your application lifecycle." />
              </Form.Label>
              <Parcel
                eleName="milestoneType"
                required
                defaultValue={milestone.milestoneType ? milestone.milestoneType : ''}
                onChange={(selected) => { handleSelectChange('milestoneType', selected.value) }}
                errorMessage="Please select Milestone type.!"
                mountParcel={mountRootParcel}
                config={Select}
                options={supportedMilestones?.map((value: any, index: number) => { return { value: value.milestoneType, label: value.milestoneType } }
                )}
              ></Parcel>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="milestoneStatusSelector">
              <Form.Label>
                <b>Status</b>
                <Parcel config={HelpText} mountParcel={mountRootParcel} wrapWith="span" text="Defines the milestone status. I.E. 'In progress' or 'Completed'" />
              </Form.Label>
              <Parcel
                eleName="isComplete"
                id="isComplete"
                required
                defaultValue={milestone.isComplete !== undefined ? milestone.isComplete.toString() : ''}
                onChange={(selected) => {
                  handleSelectChange('isComplete', selected.value)
                }}
                errorMessage={validateMilestoneStatus() ? 'Please make sure all the previous milestones are completed and feature milestone are in progress.' : 'Please select Status.'}
                mountParcel={mountRootParcel}
                config={Select}
                options={availableStatus}
              ></Parcel>
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="milestoneDate">
              <Form.Label>
                <b>Date</b>
                <Parcel config={HelpText} mountParcel={mountRootParcel} wrapWith="span" text="Target date or completion date for the Milestone" />
              </Form.Label>
              <Parcel
                config={DatePicker}
                required
                mountParcel={mountRootParcel}
                selected={milestone.milestoneDate && new Date(milestone.milestoneDate)}
                eleName="milestoneDate"
                onError={setFieldError}
                error={errors?.milestoneDate}
                onChange={(value: Date) => setMilestone({ ...milestone, milestoneDate: value })}
              />
            </Form.Group>
          </Row>
        </BaseForm>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="white" className="border" onClick={handleModalClose}>Cancel</Button>
        <Parcel
          mountParcel={mountRootParcel}
          config={AsyncButton}
          variant="primary"
          loader={loader}
          formId="milestoneForm">
          Save
        </Parcel>
      </Modal.Footer>
    </Modal>
  );
};

export default ManageMilestones;
