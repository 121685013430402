import { Form, Row, Col } from "react-bootstrap";
import Parcel from "single-spa-react/parcel";
import { mountRootParcel } from 'single-spa';
import { components } from '@deloitte-global-cloud-services//dsoe-portal-core';

const { HelpText, TextField } = components;

const Whitesource = (props) => {
    return (
        <>
            <Row className="mb-3">
                <Form.Group as={Col} md="6">
                    <Form.Label>
                        Product Name<span className="text-danger">*</span>
                        <Parcel config={HelpText} mountParcel={mountRootParcel} wrapWith="span" text="Name of your product in Whitesource." />
                    </Form.Label>
                    <Parcel
                        config={TextField}
                        mountParcel={mountRootParcel}
                        id="productName"
                        required
                        maxLength={100}
                        type="text"
                        eleName="productName"
                        data-display="Product Name"
                        defaultValue={props.profileDetails?.productName || ''}
                        onChange={(e) => { props.setProfileDetails(e.target.name, e.target.value) }}
                        onError={props.setFieldError}
                        error={props.errors?.productName}
                    />
                </Form.Group>
                <Form.Group as={Col} md="6">
                    <Form.Label>
                        Project Name<span className="text-danger">*</span>
                        <Parcel config={HelpText} mountParcel={mountRootParcel} wrapWith="span" text="Name of the project that will be displayed in the White Source (Mend) Portal." />
                    </Form.Label>
                    <Parcel
                        config={TextField}
                        mountParcel={mountRootParcel}
                        id="projectName"
                        required
                        maxLength={100}
                        type="text"
                        eleName="projectName"
                        data-display="Project Name"
                        defaultValue={props.profileDetails?.projectName || ''}
                        onChange={(e) => { props.setProfileDetails(e.target.name, e.target.value) }}
                        onError={props.setFieldError}
                        error={props.errors?.projectName}
                    />
                </Form.Group>
            </Row>
            <Row className="mb-3">
                <Form.Group as={Col} md="6">
                    <Form.Label>
                        Product Token<span className="text-danger">*</span>
                        <Parcel config={HelpText} mountParcel={mountRootParcel} wrapWith="span" text="Unique identifier of the product to update; found on the Integrate page. " />
                    </Form.Label>
                    <Parcel
                        config={TextField}
                        mountParcel={mountRootParcel}
                        id="productToken"
                        required
                        maxLength={500}
                        type="password"
                        eleName="productToken"
                        data-display="Product Token"
                        defaultValue={props.profileDetails?.productToken || ''}
                        onChange={(e) => { props.setProfileDetails(e.target.name, e.target.value) }}
                        onError={props.setFieldError}
                        error={props.errors?.productToken}
                    />
                </Form.Group>
                <Form.Group as={Col} md="6">
                    <Form.Label>
                        Project Token<span className="text-danger">*</span>
                        <Parcel config={HelpText} mountParcel={mountRootParcel} wrapWith="span" text="Unique identifier of the project to update; found on the Integrate page. " />
                    </Form.Label>
                    <Parcel
                        config={TextField}
                        mountParcel={mountRootParcel}
                        id="projectToken"
                        required
                        maxLength={500}
                        type="password"
                        eleName="projectToken"
                        data-display="Project Token"
                        defaultValue={props.profileDetails?.projectToken || ''}
                        onChange={(e) => { props.setProfileDetails(e.target.name, e.target.value) }}
                        onError={props.setFieldError}
                        error={props.errors?.projectToken}
                    />
                </Form.Group>
            </Row>
            <Row className="mb-3">
                <Form.Group as={Col} md="6">
                    <Form.Label>
                        Agent URL<span className="text-danger">*</span>
                        <Parcel config={HelpText} mountParcel={mountRootParcel} wrapWith="span" text="URL used to connect to the WS Agent" />
                    </Form.Label>
                    <Parcel
                        config={TextField}
                        mountParcel={mountRootParcel}
                        id="url"
                        required
                        maxLength={500}
                        type="url"
                        eleName="url"
                        data-display="Agent Url"
                        defaultValue={props.profileDetails?.url || ''}
                        onChange={(e) => { props.setProfileDetails(e.target.name, e.target.value) }}
                        onError={props.setFieldError}
                        error={props.errors?.url}
                    />
                </Form.Group>
                <Form.Group as={Col} md="6">
                    <Form.Label>
                        API URL<span className="text-danger">*</span>
                        <Parcel config={HelpText} mountParcel={mountRootParcel} wrapWith="span" text="Whitesource Rest API URL to download the Scan results - As of 10/20/2022 https://deloitte.whitesourcesoftware.com/api/v1.3" />
                    </Form.Label>
                    <Parcel
                        config={TextField}
                        mountParcel={mountRootParcel}
                        id="apiUrl"
                        required
                        maxLength={500}
                        type="url"
                        eleName="apiUrl"
                        data-display="API Url"
                        defaultValue={props.profileDetails?.apiUrl || ''}
                        onChange={(e) => { props.setProfileDetails(e.target.name, e.target.value) }}
                        onError={props.setFieldError}
                        error={props.errors?.apiUrl}
                    />
                </Form.Group>
            </Row>
            <Row className="mb-3">
                <Form.Group as={Col} md="6">
                    <Form.Label>
                        User Key<span className="text-danger">*</span>
                        <Parcel config={HelpText} mountParcel={mountRootParcel} wrapWith="span" text="Unique identifier of the user that can be generated from the Profile page in your WhiteSource account." />
                    </Form.Label>
                    <Parcel
                        config={TextField}
                        mountParcel={mountRootParcel}
                        id="userKey"
                        required
                        maxLength={500}
                        type="password"
                        eleName="userKey"
                        data-display="User Key"
                        defaultValue={props.profileDetails?.userKey || ''}
                        onChange={(e) => { props.setProfileDetails(e.target.name, e.target.value) }}
                        onError={props.setFieldError}
                        error={props.errors?.userKey}
                    />
                </Form.Group>
                <Form.Group as={Col} md="6">
                    <Form.Label>
                        API Key<span className="text-danger">*</span>
                        <Parcel config={HelpText} mountParcel={mountRootParcel} wrapWith="span" text="A unique identifier of your organization. Used to identify the organization in plugins." />
                    </Form.Label>
                    <Parcel
                        config={TextField}
                        mountParcel={mountRootParcel}
                        id="apiKey"
                        required
                        maxLength={500}
                        type="password"
                        eleName="apiKey"
                        data-display="API Key"
                        defaultValue={props.profileDetails?.apiKey || ''}
                        onChange={(e) => { props.setProfileDetails(e.target.name, e.target.value) }}
                        onError={props.setFieldError}
                        error={props.errors?.apiKey}
                    />
                </Form.Group>
            </Row>
        </>
    )
}

export default Whitesource;