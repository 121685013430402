import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Parcel from 'single-spa-react/parcel';
import { mountRootParcel } from 'single-spa';
import { components, util } from '@deloitte-global-cloud-services//dsoe-portal-core';
import BackendAPIService from '../../services/backendapi.service';
import { MyApplications } from "./breadcrumb-links";
import { useDSOContext } from '../../context/DataLayer';

const {Breadcrumb, Pagination} = components;
const {DateUtil} = util;

const CompianceRecords = () => {

    const { dsoAppId } = useParams();
    const [data, setData] = useState(undefined);
    const [{ dsoApplication }] = useDSOContext();

    useEffect(() => {
        BackendAPIService.getAllCompliance(dsoAppId).then(data => setData(data));
    }, []);

    return (
        <>
            <div className="row pt-3 pb-3">
                <Parcel
                    mountParcel={mountRootParcel}
                    config={Breadcrumb}
                    wrapWith="div"
                    wrapClassName='col'
                    links={[
                        ...MyApplications,
                        {
                            name: dsoApplication?.projectInfoDetails?.projectName,
                            url: `/dsoeportal/viewapp/${dsoAppId}`
                        },
                        {
                            name: 'Compliance Records'
                        }
                    ]}
                ></Parcel>
            </div>
            <div className="row">
                <div className="col">
                    <div className="bg-white pt-3 pb-3">
                        <h5 className="page-title ps-3">Compliance Records</h5>
                        <div className="row">
                            <div className="col">
                                <div className="table-container">
                                <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th>Repo Name</th>
                                                <th>Environment</th>
                                                <th>Build Version</th>
                                                <th>Compliance Code</th>
                                                <th>Compliance Record</th>
                                                <th>Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data?.complianceModels.map((compliance: any, index: number) =>
                                                (
                                                    <tr key={index}>
                                                        <td>{compliance.repoName}</td>
                                                        <td>{compliance.environment ?? ''}</td>
                                                        <td>{compliance.release_Ver}</td>
                                                        <td>{compliance.complianceCode}</td>
                                                        <td>{compliance.description}</td>
                                                        <td>{DateUtil.toDisplayDate(compliance.createdDate)}</td>
                                                    </tr>
                                                )
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {data && (
                            <Parcel
                                mountParcel={mountRootParcel}
                                className="float-end"
                                totalRows={data.totalRecords}
                                onChangePage={(startIndex: number, pageSize: number) => BackendAPIService.getAllCompliance(dsoAppId, startIndex, pageSize).then(data => setData(data))}
                                config={Pagination}
                                wrapWith="div"
                            ></Parcel>
                        )}
                    </div>

                </div>
            </div>
        </>
    );
}

export default CompianceRecords;