import { createContext, useContext, useReducer } from "react";

export const DataLayerContext = createContext(null);

interface DataLayerProps {
  initialState: any;
  reducer: any;
  children: any;
}

export const DataLayer = ({ initialState, reducer, children }: DataLayerProps) => {
  return <DataLayerContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </DataLayerContext.Provider>
}

export const useDSOContext = () => useContext(DataLayerContext);