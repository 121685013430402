import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";

/**
 * Service options for interfacing with API
 */
export type ServiceOptions = {
  /** authorization header in form: 'Bearer JWT' or 'Basic BASE64ENCODE(tenant/user:pass)' */
  authorization?: string;
};

/**
 * Override options per request
 */
export type ServiceRequestOptions = {
  authorization?: string;
  /** If set to true, treat the endpoint as full url (useful for directly calling binary URLs or something) */
  endpointIsUrl?: boolean;
};

/**
 * Wrapped response for service requests
 */
export type ServiceResponse<T> = {
  data?: T;
  success: boolean;
  error?: any;
  statusCode: any;
};

/**
 * Base layer for a API service.
 * Handles nitty gritty content stuff
 */
export abstract class BaseService {
  protected baseUrl?: string;

  protected async getAccessToken(): Promise<string> {
    const authModule = await System.import('@deloitte-dsoe/portal-auth');
    return authModule.context.value.sessionToken;
  }

  protected async getAuthoHeader(): Promise<any> {
    const token = await this.getAccessToken();
    return {
      Authorization: `Bearer ${token}`
    }
  }

  /**
   * Perform a get request
   * @param endpoint
   * @param config
   * @param contentType
   * @param options Optionally set service options just for this request
   */
  protected async makeRequest<T>(endpoint: string, config: AxiosRequestConfig, contentType?: string, options?: ServiceRequestOptions): Promise<ServiceResponse<T>> {
    const contentHeaders = this.formatContentHeaders(contentType);

    let url = `${endpoint}`;
    if (options?.endpointIsUrl === true) {
      url = endpoint;
    }

    const authHeader = await this.getAuthoHeader();
    const _config: AxiosRequestConfig = {
      ...config,
      url,
      headers: {
        ...contentHeaders,
        ...config.headers,
        ...authHeader
      }
    };

    let result: ServiceResponse<T> = {
      success: false,
      statusCode: 500,
    };
    this.appendContextValues(_config);
    await axios(_config)
      .then((response: AxiosResponse) => {
        const { data, status } = response;
        result.data = data;
        result.statusCode = status;
        result.success = true;

        // Debugging
        if (process.env.DEBUG_SHOW_SERVICE_RESPONSES === "true") {
          console.log(`Request: '${_config.method}' to '${_config.url}' succeded with status code '${status}'`);
          console.log(data);
          console.log("");
        }
      })
      .catch((error: AxiosError) => {
        if (error?.response?.data) {
          result.error = error.response.data;
          result.statusCode = error.response.status;
        } else {
          result.statusCode = 500;
          result.error = error.message;
        }
      });
    return result;
  }

  /**
   * Make a get request
   * @param endpoint
   * @param contentType
   * @param params
   */
  protected async get<T>(endpoint: string, params?: any, options?: ServiceRequestOptions, contentType?: string, _config?: AxiosRequestConfig): Promise<ServiceResponse<T>> {
    // Build request
    let config: AxiosRequestConfig =
      _config !== undefined
        ? {
            ..._config,
          }
        : {};
    config.method = "get";

    if (params !== undefined) {
      config.params = params;
    }

    return await this.makeRequest(endpoint, config, contentType, options);
  }

  /**
   * Get all pages in a collection
   * @param endpoint
   * @param itemsKey
   * @param params
   * @param options
   */
  protected async getAll<T>(endpoint: string, itemsKey: string, params?: any, options?: ServiceRequestOptions): Promise<ServiceResponse<T[]>> {
    // Build request
    let config: AxiosRequestConfig = {
      method: "get",
      params: {},
    };

    if (params !== undefined) {
      config.params = params;
    }

    if (!config.params.pageSize) {
      config.params.pageSize = 25;
    }
    if (!config.params.currentPage) {
      config.params.currentPage = 1;
    }
    const { success, error, data: pageWrapper, statusCode } = await this.makeRequest<any>(endpoint, config, "application/json", options);
    if (!success) {
      return { success, statusCode, error };
    }

    const { [itemsKey]: page } = pageWrapper;

    return {
      success: true,
      data: page as T[],
      statusCode: 200,
    };
  }

  /**
   * Make a get request
   * @param endpoint
   * @param contentType
   * @param params
   */
  protected async getBinary<Buffer>(endpoint: string, contentType?: string, params?: any, options?: ServiceRequestOptions): Promise<ServiceResponse<Buffer>> {
    // Build request
    let config: AxiosRequestConfig = {
      method: "get",
      responseType: "arraybuffer",
    };

    if (params !== undefined) {
      config.params = params;
    }

    return await this.makeRequest(endpoint, config, contentType, options);
  }

  /**
   *  Make a post request
   * @param endpoint
   * @param body
   * @param contentType
   */
  protected async post<T>(endpoint: string, body: any, contentType?: string, options?: ServiceRequestOptions): Promise<ServiceResponse<T>> {
    // Build request
    let config: AxiosRequestConfig = {
      method: "post",
      data: body,
    };

    return await this.makeRequest(endpoint, config, contentType, options);
  }

  /**
   *  Make a put request
   * @param endpoint
   * @param body
   * @param contentType
   */
  protected async put<T>(endpoint: string, body?: any, contentType?: string, options?: ServiceRequestOptions): Promise<ServiceResponse<T>> {
    // Build request
    let config: AxiosRequestConfig = {
      method: "put",
      data: body,
    };

    return await this.makeRequest(endpoint, config, contentType, options);
  }

  /**
   *  Make a delete request
   * @param endpoint
   * @param body
   * @param contentType
   */
  protected async delete<T>(endpoint: string, body?: any, contentType?: string, options?: ServiceRequestOptions): Promise<ServiceResponse<T>> {
    // Build request
    let config: AxiosRequestConfig = {
      method: "delete",
    };
    if (body !== undefined) config["data"] = body;

    return await this.makeRequest(endpoint, config, contentType, options);
  }

  /**
   * Format dumb content type headers per request type
   * @param contentType
   */
  protected formatContentHeaders(contentType?: string): any {
    if (contentType === undefined) {
      return {};
    }

    let contentTypeHeader = "application/json";
    return {
      "Content-Type": contentTypeHeader,
      Accept: contentTypeHeader,
    };
  }

  protected appendContextValues(_config: AxiosRequestConfig) {}
}
