import { Form, Row, Col } from "react-bootstrap";
import Parcel from "single-spa-react/parcel";
import { mountRootParcel } from 'single-spa';
import { components } from '@deloitte-global-cloud-services//dsoe-portal-core';

const { HelpText, TextField } = components;

const SonarQube = (props) => {
    return (
        <>
            <Row className="mb-3">
                <Form.Group as={Col} md="6">
                    <Form.Label>
                        Project Name<span className="text-danger">*</span>
                        <Parcel config={HelpText} mountParcel={mountRootParcel} wrapWith="span" text="Name of the project that will be displayed on the web interface." />
                    </Form.Label>
                    <Parcel
                        config={TextField}
                        mountParcel={mountRootParcel}
                        id="projectName"
                        required
                        maxLength={100}
                        type="text"
                        eleName="projectName"
                        data-display="project Name"
                        defaultValue={props.profileDetails?.projectName || ''}
                        onChange={(e) => { props.setProfileDetails(e.target.name, e.target.value) }}
                        onError={props.setFieldError}
                        error={props.errors?.projectName}
                    />
                </Form.Group>
                <Form.Group as={Col} md="6">
                    <Form.Label>
                        SonarQube URL<span className="text-danger">*</span>
                        <Parcel config={HelpText} mountParcel={mountRootParcel} wrapWith="span" text="SonarQube server URL - https://dso-sonarqube-instance-as.azurewebsites.net" />
                    </Form.Label>
                    <Parcel
                        config={TextField}
                        mountParcel={mountRootParcel}
                        id="url"
                        required
                        maxLength={100}
                        type="url"
                        eleName="url"
                        data-display="SonarQube URL"
                        defaultValue={props.profileDetails?.url || ''}
                        onChange={(e) => { props.setProfileDetails(e.target.name, e.target.value) }}
                        onError={props.setFieldError}
                        error={props.errors?.url}
                    />
                </Form.Group>
            </Row>
            <Row className="mb-3">
                <Form.Group as={Col} md="6">
                    <Form.Label>
                        SonarQube Login<span className="text-danger">*</span>
                        <Parcel config={HelpText} mountParcel={mountRootParcel} wrapWith="span" text="The authentication token or login of a SonarQube user with Execute Analysis permission on the project." />
                    </Form.Label>
                    <Parcel
                        config={TextField}
                        mountParcel={mountRootParcel}
                        id="sonarLogin"
                        required
                        maxLength={500}
                        type="password"
                        eleName="sonarLogin"
                        data-display="SonarQube Login"
                        defaultValue={props.profileDetails?.sonarLogin || ''}
                        onChange={(e) => { props.setProfileDetails(e.target.name, e.target.value) }}
                        onError={props.setFieldError}
                        error={props.errors?.sonarLogin}
                    />
                </Form.Group>
                <Form.Group as={Col} md="6">
                    <Form.Label>
                        Project Key<span className="text-danger">*</span>
                        <Parcel config={HelpText} mountParcel={mountRootParcel} wrapWith="span" text="The project's unique key. Allowed characters are: letters, numbers, -, _, . and :, with at least one non-digit." />
                    </Form.Label>
                    <Parcel
                        config={TextField}
                        mountParcel={mountRootParcel}
                        id="projectKey"
                        required
                        maxLength={500}
                        type="password"
                        eleName="projectKey"
                        data-display="Project Key"
                        defaultValue={props.profileDetails?.projectKey || ''}
                        onChange={(e) => { props.setProfileDetails(e.target.name, e.target.value) }}
                        onError={props.setFieldError}
                        error={props.errors?.projectKey}
                    />
                </Form.Group>
            </Row>
        </>
    )
}

export default SonarQube;