import { useEffect, useState } from "react";
import * as ReactDOMClient from 'react-dom/client';
import { Modal, Alert, Row, Col } from "react-bootstrap";

const ReactMessageAlert = (props: any) => {

    const handleCloseModal = () => {
        removeElement();
        typeof props.onClose === 'function' && props.onClose();
    }

    return (
        <Modal show={true} onHide={handleCloseModal} className="notification">
            <Modal.Header closeButton className="border-0">
                <Modal.Title className="page-title">&nbsp;</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="text-center p-4">
                    <img src="/assets/images/success.svg" height="90" alt="Success"/>
                </div>
                <h3 className="text-center">{props.error ? 'Error!' : 'Success.'}</h3>
                <div className="text-center pt-2 pb-2">{props.message}</div>
            </Modal.Body>
        </Modal>
    );
}

let root = null
const targetId = 'notification-alert';
const removeElement = () => {
    const target = document.getElementById(targetId);
    if (target) {
      root.unmount(target);
      target.remove()
    }
}

export const alertMessage = (properties?: any) => {

    const divTarget = document.createElement('div')
    divTarget.id = targetId
    document.body.appendChild(divTarget)
    root = ReactDOMClient.createRoot(divTarget)
    root.render(<ReactMessageAlert {...properties} />);
}

const ReactMessageNotification = (props: any) => {
    const messages = props.message && props.message.split('\n');

    useEffect(() => {
        props.root && props.root.scrollIntoView()
    });

    return (
        <>
            {messages?.length && (
                <Row className="mb-3">
                    <Col>
                        <Alert variant="danger" dismissible>
                            {messages.map((msg, index) => (<p key={index} dangerouslySetInnerHTML={{__html: msg}}/>))}
                            <button type="button" onClick={() => {
                                removeNotificationElement();
                                props.oClose && props.oClose();
                            }} className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                        </Alert>
                    </Col>
                </Row>
            )}
        </>
    );
}

const notificationDivId = 'dsoe-alert-popup-messages';
let notificationRoot;
export const showMessageOnPopup = (properties?: any) => {
    removeNotificationElement();
    const rootElement = document.querySelector(".modal-body");
    const divTarget = document.createElement('div');
    divTarget.id = notificationDivId;
    rootElement.appendChild(divTarget);
    notificationRoot = ReactDOMClient.createRoot(divTarget)
    notificationRoot.render(<ReactMessageNotification root={divTarget} {...properties} />);
}

const removeNotificationElement = () => {
    const target = document.getElementById(notificationDivId);
    if (target) {
        notificationRoot.unmount(target);
        target.remove()
    }
}