import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Parcel from "single-spa-react/parcel";
import { mountRootParcel } from 'single-spa';
import { components, util } from '@deloitte-global-cloud-services//dsoe-portal-core';
import BackendAPIService from "../../services/backendapi.service";
import { MyApplications } from "./breadcrumb-links";
import APIUtil from "../../utils/APIUtil";
import { useDSOContext } from "../../context/DataLayer";
import moment from "moment";
import { CustomTable } from "../../components/CustomTable";
import { Tooltip } from "../../components/Tooltip";

const { Breadcrumb } = components;
const { DateUtil } = util;

const ScanStatus = {
    Passed: 1,
    NotStarted: 4,
    Failed: 2,
    InProgress: 3
}

const ScanStatusText = {
    1: 'Completed',
    4: 'NotStarted',
    2: 'Interrupted',
    3: 'InProgress'
}

const FortifyThreshold = 4

const ScanResults = () => {

    const { dsoAppId } = useParams();
    const [data, setData] = useState(undefined);
    const [allData, setAllData] = useState(undefined);
    const [filteredData, setFilteredData] = useState(undefined);
    const [searchText, setSearchText] = useState('');
    const [{ dsoApplication }] = useDSOContext();

    useEffect(() => {
        BackendAPIService.getAllScanResults(dsoAppId).then(data => setData(data));
    }, []);

    useEffect(() => {
        BackendAPIService.getAllScanResults(dsoAppId, data?.startIndex, data?.totalRecords).then(allData => setAllData(allData));
    }, [data]);

    useEffect(() => {
        setFilteredData(allData?.scanResultList);
    }, [allData]);

    const viewScanResult = async (data: any, isLog?: boolean) => {
        const fileContent = await APIUtil.downloadScanResult(data, isLog);
        if (typeof data === 'string') data = JSON.parse(data);
        const link = document.createElement('a');
        if (data.scanEngine === 'sonarqube' && !isLog) {
            link.href = fileContent;
            link.target = '_blank';
        } else {
            link.href = window.URL.createObjectURL(fileContent);
            link.setAttribute('download', data.file);
        }
        document.body.appendChild(link);
        link.click();
    }

    const scoreColumn = (score: any, status: any) => {
        if (isNaN(score)) {
            if (parseInt(status) == 1) {
                return <Tooltip content="Please contact the PE team." title="Error" />;
            } else if (parseInt(status) == 2) {
                return <Tooltip content="Scan didn't complete." title="NA" />;
            }
        } else {
            if (parseInt(score) < FortifyThreshold) {
                return <Tooltip content="For detailed list of vulnerabilities, please visit Fortify SSC portal. Click on your SAST application version 
                and open Audit tab." title={score} />;
            }
            return score;
        }
    }

    const sortScanStarted = (rowA: any, rowB: any) => {
        const dateA = moment.utc(rowA.scanStarted).toDate();
        const dateB = moment.utc(rowB.scanStarted).toDate();

        if (dateA > dateB) {
            return 1;
        }

        if (dateB > dateA) {
            return -1;
        }

        return 0;
    };

    const columns = [
        {
            name: 'Repo Name',
            selector: (row: { reponame: any; }) => row.reponame,
            sortable: true,
            wrap: true,
            grow: 1,
        },
        {
            name: 'Scan Type',
            selector: (row: { scanType: any; }) => row.scanType,
            sortable: true,
            wrap: true,
            grow: 0.55,
        },
        {
            name: 'Status',
            selector: (row: { scanStatus: string; }) => ScanStatusText[(parseInt(row.scanStatus))],
            sortable: true,
            wrap: true,
            grow: 0.65,
        },
        {
            name: 'Environment',
            selector: (row: { environment: any; }) => row.environment,
            sortable: true,
            wrap: true,
            grow: 0.70,
        },
        {
            name: 'Build Version',
            selector: (row: { buildNumber: any; }) => row.buildNumber,
            sortable: true,
            wrap: true,
            grow: 0.75,
        },
        {
            name: 'Date',
            selector: (row: { scanStarted: moment.MomentInput; }) => DateUtil.toDisplayDateTime(moment.utc(row.scanStarted).toDate()),
            sortable: true,
            sortFunction: sortScanStarted,
            wrap: true,
            center: 'false',
            grow: 1,
        },
        {
            name: (<Tooltip content="Applicable to Fortify-SAST/DAST Scan Types only." title="Score" />),
            selector: (row: { scanType: string | string[]; scanDetails: string; scanStatus: any; }) => row.scanType.includes("fortify") ? scoreColumn(JSON.parse(row.scanDetails)?.scanScore, row.scanStatus) : "NA",
            sortable: true,
            wrap: true,
            center: 'true',
            grow: 0.70,
        },
        {
            name: (<Tooltip content="Applicable to Unit Test Scan Types only." title="Unit Test Coverage %" />),
            selector: (row: { scanType: string | string[]; scanDetails: string; }) => row.scanType.includes("unittesting") ? JSON.parse(row.scanDetails)?.scanScore : "NA",
            sortable: true,
            wrap: true,
            center: 'true',
            grow: 1.25,
        },
        {
            name: (<Tooltip content="Applicable to Unit Test Scan Types only." title="Unit Test Status" />),
            selector: (row: { scanType: string | string[]; scanDetails: string; }) => row.scanType.includes("unittesting") ?
                (JSON.parse(row.scanDetails)?.unitTestStatus != null ? JSON.parse(row.scanDetails)?.unitTestStatus : "NA") : "NA",
            sortable: true,
            wrap: true,
            center: 'false',
            grow: 1,
        },
        {
            name: 'Report',
            selector: (row: { scanDetails: string; }) => JSON.parse(row.scanDetails)?.status === ScanStatus.Passed ?
                (<a title="Download Result" href={undefined} onClick={() => viewScanResult(JSON.parse(row.scanDetails)?.publishReportRes?.message ?? JSON.parse(row.scanDetails)?.publishReportRes)}>
                    <img className="action_icon" src="/assets/images/icon-eye@2x.png" />
                </a>) : 'NA',
            sortable: false,
            wrap: true,
            center: 'true',
            grow: 0.25,
        },
        {
            name: 'Logs',
            selector: (row: { scanDetails: string; }) => JSON.parse(row.scanDetails)?.scanLogRes?.message ?
                (<a title="Download Logs" href={undefined} onClick={() => viewScanResult(JSON.parse(row.scanDetails)?.scanLogRes?.message, true)}>
                    <img className="action_icon" src="/assets/images/icon-eye@2x.png" />
                </a>) : 'NA',
            sortable: false,
            wrap: true,
            center: 'true',
            grow: 0.25,
        },
    ]

    //Global Search
    const handleSearchChange = (event: { target: { value: any; }; }) => {
        setSearchText(event.target.value);

        const filtered = allData?.scanResultList?.filter((column: { [x: string]: any; }) => {

            for (let key in column) {

                if (key !== 'dsoAppId') {  //exclude columns for global search
                    let value = column[key];

                    if (key === 'scanStatus') {
                        value = ScanStatusText[value];
                    }

                    if (key === 'scanStarted') {
                        value = DateUtil.toDisplayDateTime(moment.utc(value).toDate());
                    }

                    if (key === 'scanDetails') {
                        const scanDetails = JSON.parse(value);

                        for (let key in scanDetails) {
                            if (key === 'scanScore' || key === 'unitTestStatus') {
                                value = scanDetails[key];

                                if (value.toLowerCase().includes(event.target.value.toLowerCase())) {
                                    return true;
                                }
                            }
                        }
                    }

                    if (value.toLowerCase().includes(event.target.value.toLowerCase())) {
                        return true;
                    }
                }
            }
            return false;
        });

        setFilteredData(filtered);
    }

    return (
        <>
            <div className="row pt-3 pb-3">
                <Parcel
                    mountParcel={mountRootParcel}
                    config={Breadcrumb}
                    wrapWith="div"
                    wrapClassName='col'
                    links={[
                        ...MyApplications,
                        {
                            name: dsoApplication?.projectInfoDetails?.projectName,
                            url: `/dsoeportal/viewapp/${dsoAppId}`
                        },
                        {
                            name: 'Scan Results'
                        }
                    ]}
                ></Parcel>
            </div>
            <div className="row">
                <div className="col">
                    <div className="bg-white pt-3 pb-3">
                        <h5 className="page-title ps-3">Scan Results</h5>
                        <div className="d-flex offset-md-9">
                            <input
                                className="form-control"
                                type="text"
                                placeholder="Search"
                                value={searchText}
                                onChange={handleSearchChange}
                            />
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="table-container">
                                    <CustomTable
                                        columns={columns}
                                        data={filteredData}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ScanResults;