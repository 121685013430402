import { Form, Row, Col, InputGroup } from "react-bootstrap";

const PrismaCode = (props) => {
    return (
        <>
            <Row className="mb-3">
                <Form.Group as={Col} md="6">
                    <Form.Label>
                        Console URL<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                        required
                        name="prismaConsoleUrl"
                        value={props.profileDetails?.prismaConsoleUrl}
                        onChange={(e) => { props.setProfileDetails(e.target.name, e.target.value) }}
                        type="url"
                    />
                    <Form.Control.Feedback type="invalid">This field is required.!</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="6">
                    <Form.Label>
                        Prisma Repository<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                        required
                        name="prismaRepository"
                        value={props.profileDetails?.prismaRepository}
                        onChange={(e) => { props.setProfileDetails(e.target.name, e.target.value) }}
                        type="Text"
                    />
                </Form.Group>
            </Row>
            <Row className="mb-3">
                <Form.Group as={Col} md="6">
                    <Form.Label>
                        User Name<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                        required
                        name="prismaUserName"
                        value={props.profileDetails?.prismaUserName}
                        onChange={(e) => { props.setProfileDetails(e.target.name, e.target.value) }}
                        type="Text"
                    />
                    <Form.Control.Feedback type="invalid">value required.!</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="6">
                    <Form.Label>
                        Password<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                        required
                        name="prismaPassword"
                        value={props.profileDetails?.prismaPassword}
                        onChange={(e) => { props.setProfileDetails(e.target.name, e.target.value) }}
                        type="password"
                    />
                    <Form.Control.Feedback type="invalid">value required.!</Form.Control.Feedback>
                </Form.Group>
            </Row>
        </>
    )
}

export default PrismaCode